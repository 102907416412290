type arrowProps = {
  width?: string | "16";
  height?: string | "16";
};

export default function ArrowUp(props: arrowProps) {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 17L11.5154 7.24045C11.5775 7.16454 11.6526 7.10405 11.736 7.06269C11.8193 7.02134 11.9092 7 12 7C12.0908 7 12.1807 7.02134 12.264 7.06269C12.3474 7.10405 12.4225 7.16454 12.4846 7.24045L21 17"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
