import { OnClickOutside } from "@impulso/common/common/utilities/OnClickoutside";
import ArrowRight from "@impulso/common/Icons/ArrowRight";
import CalenderIcon from "@impulso/common/Icons/CalenderIcon";
import { colors } from "@impulso/common/Theme";
import { Divider, Indicator } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { endOfMonth } from "date-fns/endOfMonth";
import { endOfQuarter } from "date-fns/endOfQuarter";
import { endOfWeek } from "date-fns/endOfWeek";
import { format } from "date-fns/format";
import { isSameDay } from "date-fns/isSameDay";
import { lastDayOfYear } from "date-fns/lastDayOfYear";
import { startOfMonth } from "date-fns/startOfMonth";
import { startOfQuarter } from "date-fns/startOfQuarter";
import { startOfWeek } from "date-fns/startOfWeek";
import { startOfYear } from "date-fns/startOfYear";
import { subDays } from "date-fns/subDays";
import { subMonths } from "date-fns/subMonths";
import { subWeeks } from "date-fns/subWeeks";
import { subYears } from "date-fns/subYears";
import React, { Dispatch, SetStateAction, useCallback, useRef, useState } from "react";

interface DateRangeItem {
  label: string;
  value: [Date, Date];
}

export function CalenderButton(props: {
  setDateSpan: (value: [Date, Date]) => void;
  dateSpan: [Date, Date];
  boxSize?: string;
  defaultTitle?: string;
}) {
  const dateRanges: Record<string, DateRangeItem> = {
    last30Days: { label: "Last 30 Days", value: [subDays(new Date(), 30), new Date()] },

    thisWeek: {
      label: "This Week",
      value: [startOfWeek(new Date(), { weekStartsOn: 1 }), endOfWeek(new Date(), { weekStartsOn: 1 })],
    },
    thisMonth: {
      label: "This Month",
      value: [new Date(startOfMonth(new Date())), new Date(endOfMonth(new Date()))],
    },
    thisQuarter: {
      label: "This Quarter",
      value: [new Date(startOfQuarter(new Date())), new Date(endOfQuarter(new Date()))],
    },
    thisYear: {
      label: "This Year",
      value: [new Date(startOfYear(new Date())), new Date(lastDayOfYear(new Date()))],
    },

    lastWeek: {
      label: "Last Week",
      value: [
        new Date(subWeeks(startOfWeek(new Date(), { weekStartsOn: 1 }), 1)),
        new Date(subWeeks(endOfWeek(new Date(), { weekStartsOn: 1 }), 1)),
      ],
    },
    lastMonth: {
      label: "Last Month",
      value: [new Date(subMonths(startOfMonth(new Date()), 1)), new Date(endOfMonth(subMonths(new Date(), 1)))],
    },
    lastQuarter: {
      label: "Last Quarter",
      value: [new Date(subMonths(startOfQuarter(new Date()), 3)), new Date(subMonths(endOfQuarter(new Date()), 3))],
    },
    lastYear: {
      label: "Last Year",
      value: [new Date(subYears(startOfYear(new Date()), 1)), new Date(subYears(lastDayOfYear(new Date()), 1))],
    },
  };

  function dateRangeToString(range: [Date, Date]): string {
    return format(range[0], "yyyy-MM-dd") + "->" + format(range[1], "yyyy-MM-dd");
  }

  function identifyDateRange(range: [Date, Date]): DateRangeItem {
    const compString = dateRangeToString(range);

    for (const key in dateRanges) {
      const item = dateRanges[key];
      const itemString = dateRangeToString(item.value);

      if (itemString === compString) {
        return item;
      }
    }

    return { label: `${format(range[0], "d MMM yy")} - ${format(range[1], "d MMM yy")}`, value: range };
  }

  const [open, setOpen] = useState(false);
  const [openCalender, setOpenCalender] = useState(false);
  const currentRange = identifyDateRange(props.dateSpan);

  const wrapperRef = useRef(null);
  OnClickOutside(
    wrapperRef,
    useCallback(() => {
      setOpen(false);
      setOpenCalender(false);
    }, []),
  );

  const bgCol = "bg-white";
  const textSize = "text-M";
  const boxSize = props.boxSize ?? "w-[199px] h-[30px]";
  const hoverStyle = "hover:bg-brand-600 hover:text-white hover:border-brand-600 hover:cursor-pointer";
  const hoverGrayStyle = "hover:bg-gray-200 hover:text-brand-600 hover:border-gray-200 hover:cursor-pointer";
  const activeStyle = "active:bg-brand-900 active:text-black active:border-brand-900 hover:cursor-pointer";
  const focusStyle = "focus:bg-brand-900 focus:text-black focus:border-brand-900 hover:cursor-pointer";

  return (
    <>
      <div className={`relative ${boxSize}`} ref={wrapperRef}>
        <div className="flex justify-center h-full ">
          <div
            className={`flex items-center justify-between w-full h-full border pl-2 pr-2 border-gray-300 select-none ${bgCol} ${hoverStyle} ${activeStyle} ${focusStyle}`}
            onClick={() => {
              if (!openCalender) {
                setOpen(!open);
              } else {
                setOpenCalender(false);
              }
            }}
          >
            <div className="flex w-full justify-left">
              <p className={`pl-3  ${textSize}`}>{currentRange.label}</p>
            </div>
            <div className="w-4 h-4 mr-3">{<CalenderIcon />}</div>
          </div>
        </div>

        <div
          className={`absolute ${open ? "" : "scale-y-0 opacity-0"} origin-top right-0 bg-white drop-shadow-md w-[296px] z-20 transition-all`}
        >
          <div className="w-full grid grid-cols-2 p-2">
            <div className="p-2 text-gray-600 font-[400] text-s(c) uppercase">Date Range</div>

            {/*RELATIVE*/}
            <DateElement setOpen={setOpen} setValue={props.setDateSpan} dateRangeItem={dateRanges["last30Days"]} />

            <div className="col-span-2 p-2">
              <Divider />
            </div>

            {/*THIS*/}
            <DateElement setOpen={setOpen} setValue={props.setDateSpan} dateRangeItem={dateRanges["thisWeek"]} />
            <DateElement setOpen={setOpen} setValue={props.setDateSpan} dateRangeItem={dateRanges["thisMonth"]} />
            <DateElement setOpen={setOpen} setValue={props.setDateSpan} dateRangeItem={dateRanges["thisQuarter"]} />
            <DateElement setOpen={setOpen} setValue={props.setDateSpan} dateRangeItem={dateRanges["thisYear"]} />

            <div className="col-span-2 p-2">
              <Divider />
            </div>

            {/*LAST*/}
            <DateElement setOpen={setOpen} setValue={props.setDateSpan} dateRangeItem={dateRanges["lastWeek"]} />
            <DateElement setOpen={setOpen} setValue={props.setDateSpan} dateRangeItem={dateRanges["lastMonth"]} />
            <DateElement setOpen={setOpen} setValue={props.setDateSpan} dateRangeItem={dateRanges["lastQuarter"]} />
            <DateElement setOpen={setOpen} setValue={props.setDateSpan} dateRangeItem={dateRanges["lastYear"]} />

            <div className="col-span-2 p-2">
              <Divider />
            </div>

            <div
              className={`flex col-span-2 justify-between p-2 gap-2 text-xs ${hoverGrayStyle}`}
              onClick={() => {
                setOpen(false);
                setOpenCalender(!openCalender);
              }}
            >
              <p>Custom date range</p>
              <div className="text-gray-600 font-[400] text-s(c)">
                <ArrowRight />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`absolute ${openCalender ? "" : "scale-y-0 opacity-0"} " origin-top right-0 bg-white drop-shadow-md w-auto h-auto z-20 transition-all`}
        >
          <DatePicker
            className="pl-3"
            type="range"
            numberOfColumns={2}
            styles={{
              calendar: {
                "& .mantine-UnstyledButton-root": {
                  flexDirection: "column-reverse",
                  display: "flex",
                },
                "&  .mantine-DatePicker-monthLevelGroup": {
                  flexDirection: "column",
                },
                "& .mantine-Day-day[data-selected][data-first-in-range]": {
                  color: colors.brand[600],
                  backgroundColor: colors.brand[100],
                  borderTopLeftRadius: "100px",
                  borderBottomLeftRadius: "100px",
                },
                "& .mantine-Day-day[data-selected][data-last-in-range]": {
                  color: colors.brand[600],
                  backgroundColor: colors.brand[100],
                  borderTopRightRadius: "100px",
                  borderBottomRightRadius: "100px",
                },
                "& .mantine-Day-day[data-weekend]": {
                  color: colors.black,
                },
              },
            }}
            renderDay={d => (
              <Indicator
                size={3}
                disabled={!(isSameDay(d, currentRange.value[0]) || isSameDay(d, currentRange.value[1]))}
                position="bottom-center"
              >
                {d.getDate()}
              </Indicator>
            )}
            withCellSpacing={false}
            columnsToScroll={1}
            hideOutsideDates={false}
            onChange={dates => {
              if (dates[0] != undefined && dates[1] != undefined) {
                const fix: any = dates;
                props.setDateSpan(fix);
                setOpenCalender(false);
              }
            }}
          />
        </div>
      </div>
    </>
  );
}
function DateElement(props: {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setValue: (value: [Date, Date]) => void;
  dateRangeItem: DateRangeItem;
}) {
  const hoverGrayStyle = "hover:bg-gray-200 hover:text-brand-600 hover:border-gray-200 hover:cursor-pointer";

  return (
    <div
      className={`flex col-span-2 p-2 justify-between text-xs ${hoverGrayStyle}`}
      onClick={() => {
        props.setOpen(false);
        props.setValue(props.dateRangeItem.value);
      }}
    >
      <p>{props.dateRangeItem.label}</p>
      <p className="text-gray-600 font-[400] text-s(c)">
        {format(props.dateRangeItem.value[0], "d MMM")} - {format(props.dateRangeItem.value[1], "d MMM yy")}
      </p>
    </div>
  );
}
