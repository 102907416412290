import { createApi } from "@reduxjs/toolkit/query/react";
import { GlobalAppsettings } from "../common/GlobalSettings";
import { baseQuery } from "./BaseQuery";
import { Organisation, OrganisationId } from "src/UserProfile";

/**
 * Base api for accessing organisation api
 */
export const organisationApi = createApi({
  reducerPath: "organisationApi",
  baseQuery: baseQuery(GlobalAppsettings.BaseUrl),
  tagTypes: ["organisation", "agreement"],
  endpoints: _builder => ({}),
});

export const organisationEndpoints = organisationApi.injectEndpoints({
  endpoints: builder => ({
    createOrganisation: builder.mutation<OrganisationEndpointResponse<any>, { body: CreateOrganisationBody }>({
      query: ({ body }) => ({
        url: `/api/organisation/CreateOrganisation`,
        method: "POST",
        body,
      }),
    }),
    getOrganisation: builder.query<GetOrganisationResponse, { query: { orgId: OrganisationId } }>({
      query: ({ query }) => ({
        url: `/api/organisation/GetOrganisation?orgId=${query.orgId}`,
      }),
      providesTags: ["organisation"],
    }),
    updateOrganisation: builder.mutation<
      GetOrganisationResponse,
      { query: { orgId: OrganisationId }; body: Organisation | undefined }
    >({
      query: ({ query, body }) => ({
        url: `/api/organisation/UpdateOrganisation?orgId=${query.orgId}`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["organisation"],
    }),
    searchOrganisation: builder.query<
      OrganisationEndpointResponse<any>,
      { query: { search: string; isSupplier?: boolean } }
    >({
      query: ({ query }) => ({
        url: `/api/organisation/SearchOrganisations?search=${query.search}${query.isSupplier === undefined ? "" : `&isSupplier=${query.isSupplier}`}`,
      }),
      providesTags: ["organisation"],
    }),
  }),
});

export interface OrganisationEndpointResponse<T> {
  content?: T;
  status: "FAILED" | "SUCCESS" | "NOT_FOUND";
  error: string;
}

export type GetOrganisationResponse = OrganisationEndpointResponse<OrganisationData>;

export interface OrganisationData {
  id: OrganisationId;
  rootOrgId: string;
  rootOrgName: string;
  modules: string[];
  parentOrgId?: string;
  parentOrgName?: string;
  name: string;
  isRetailer: boolean;
  isStore: boolean;
  isSupplier: boolean;
  isRealEstate: boolean;
  currency: string;
  includeVat: boolean;
  swedishOrganisationNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  dataSource: string;
  pingKycStatus: string;
  pingMerchantId: string;
  pingTaxMerchantId: string;
  pingMandate: string;
  isActive: boolean;
  brands: string[];
  storeCount: number;
  integrationName: string;
}

export interface CreateOrganisationBody {
  parentOrgId?: string;
  name: string;
  isRetailer: boolean;
  isStore: boolean;
  isSupplier: boolean;
  isRealEstate: boolean;
  currency: string;
  includeVat: boolean;
  swedishOrganisationNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  dataSource: string;
  organisationModulesRequest: {
    retailerPayment: boolean;
    retailerTracking: boolean;
    supplierPayment: boolean;
    supplierTracking: boolean;
  };
  brands: string[];
}

export interface UpdateOrganisationBody {
  parentOrgId?: string;
  name: string;
  isRetailer: boolean;
  isStore: boolean;
  isSupplier: boolean;
  isRealEstate: boolean;
  currency: string;
  includeVat: boolean;
  swedishOrganisationNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  dataSource: string;
  organisationModulesRequest: {
    retailerPayment: boolean;
    retailerTracking: boolean;
    supplierPayment: boolean;
    supplierTracking: boolean;
  };
  brands: string[];
}

export const {
  useCreateOrganisationMutation,
  useUpdateOrganisationMutation,
  useGetOrganisationQuery,
  useSearchOrganisationQuery,
} = organisationEndpoints;
