import { OrganisationId } from "src/UserProfile";
import { VolumeFeePlan } from "../AgreementApi";

export interface TrackedWholesale {
  suppliers: SupplierWholesale[];
  trackedQuantity: number;
  totalWholesale: number;
  payingCustomers: number;
  allCustomers: number;
  sekConversionRate: number;
  nokConversionRate: number;
  dkkConversionRate: number;
  gbpConversionRate: number;
}

export interface SupplierWholesale {
  supplierName: string;
  supplierOrgId: OrganisationId;
  isPaying: boolean;
  totalTrackedQuantity: number;
  totalWholesale: number;
  retailerCount: number;
}

export interface SupplierInvoiceWholesale {
  paymentId: string;
  supplierName: string;
  supplierOrgId: OrganisationId;
  agreementCurrency: string;
  orgNr: string;
  totalTrackedQuantity: number;
  totalWholesaleFee: number;
  totalWholesale: number;
  feePlans: VolumeFeePlan[];
  status: Status;
  retailerCount: number;
  createdInvoice: string;
  invoiceMonth: string;
}

export enum Status {
  Staged,
  Pending,
  Declined,
  Completed,
}
