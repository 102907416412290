import { Page } from "@impulso/common/components/Page";
import CreateOrganisationPanel from "src/modules/organisations/CreateOrganisation";
import UpdateImpulsoAgreementPanel from "src/modules/organisations/UpdateImpulsoAgreement";
import UpdateOrganisationPanel from "src/modules/organisations/UpdateOrganisation";

export default function Dashboard() {
  return (
    <Page grid responsive hasAccess={true} titleKey="dashboard.title">
      <CreateOrganisationPanel />
      <UpdateOrganisationPanel />
      <UpdateImpulsoAgreementPanel />
      <div className="h-[200px]" />
    </Page>
  );
}
