import DownChevronIcon from "@impulso/common/Icons/DownChevronIcon";
import Download from "@impulso/common/Icons/Download";
import UpChevronIcon from "@impulso/common/Icons/UpChevronIcon";
import { Language } from "@impulso/common/Language";
import { colors } from "@impulso/common/Theme";
import { exportPdf } from "@impulso/common/common/pdfExport";
import { Page } from "@impulso/common/components/Page";
import CustomSelectElement from "@impulso/common/components/buttons/CustomDropdown";
import { CalenderButton } from "@impulso/common/components/calender/CalenderButton";
import { AsyncLinkDownload } from "@impulso/common/components/link";
import FormatDate from "@impulso/common/styling/FormatDate";
import { Accordion, LoadingOverlay, Skeleton } from "@mantine/core";
import { endOfWeek } from "date-fns/endOfWeek";
import { format } from "date-fns/format";
import { startOfWeek } from "date-fns/startOfWeek";
import { subDays } from "date-fns/subDays";
import { Dispatch, Key, SetStateAction, useCallback, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ProductTrackerFeePlan } from "src/pages/agreements/FeePlan";
import { downloadProductTrackerPaymentForSupplier } from "../api/DownloadApi";
import {
  useGetMonthlyTrackedWholesaleQuery,
  useUpdateProductTrackerPaymentStatusMutation,
} from "../api/ProductTrackerApi";
import { Status, SupplierInvoiceWholesale } from "../api/models/ProductTracker";
import { useGlobalSecurity } from "../common/UseGlobalSecurity";

export default function TrackedWholesaleInvoice() {
  const location = useLocation();
  const locDate = location.state as Date | null;

  const last30Days: [Date, Date] = [subDays(new Date(), 30), new Date()];
  const [dateSpan, setDateSpan] = useState<[Date, Date]>([
    locDate ? startOfWeek(locDate) : last30Days[0],
    locDate ? endOfWeek(locDate) : last30Days[1],
  ]);
  const startDate = format(dateSpan[0], "yyyy-MM-dd");
  const endDate = format(dateSpan[1], "yyyy-MM-dd");

  const { accessToken } = useGlobalSecurity();

  const { data: rawData, isFetching: isLoading } = useGetMonthlyTrackedWholesaleQuery({ startDate, endDate });
  const tracked = useMemo(() => rawData ?? [], [rawData]);

  const downloadBreakdown = useCallback(
    (paymentId: string, supplierName: string, invoiceMonth: string) => {
      return downloadProductTrackerPaymentForSupplier(paymentId, supplierName, invoiceMonth, accessToken!);
    },
    [accessToken],
  );

  return (
    <Page
      hasAccess={true}
      titleKey="trackedInvoice.title"
      rightAction={<PayoutHeader dateSpan={dateSpan} setDateSpan={setDateSpan} />}
    >
      <LoadingOverlay visible={isLoading} />
      <Accordion
        multiple={true}
        chevron={<DownChevronIcon></DownChevronIcon>}
        styles={{
          item: {
            backgroundColor: "white",
            borderColor: colors.gray[300],
            borderRadius: "0px",
          },
        }}
        variant="separated"
      >
        {tracked.map((item: SupplierInvoiceWholesale, index: Key | null | undefined) => {
          return (
            <TrackedInvoiceItem
              key={index}
              id={index?.toString() ?? ""}
              tracked={item}
              accessToken={accessToken ?? ""}
              loading={isLoading}
              onClick={() => downloadBreakdown(item.paymentId, item.supplierName, item.invoiceMonth)}
            />
          );
        })}
      </Accordion>
    </Page>
  );
}

type TrackedInvoiceProps = {
  tracked: SupplierInvoiceWholesale;
  accessToken: string;
  loading: boolean;
  id: string;
  isSummary?: boolean;
  onClick: () => Promise<void>;
};
function TrackedInvoiceItem(props: TrackedInvoiceProps) {
  const [isOpen, setOpen] = useState(false);
  const reportElement = useRef<HTMLDivElement | null>(null);

  const headerClasses = "text-gray-600 font-[400] text-sc uppercase";
  const textHeaderClasses = "text-black font-[400] text-M";
  const [status, setStatus] = useState(props.tracked.status);

  const elementName = `Invoice-${props.tracked.supplierName}-${props.tracked.createdInvoice}`;

  const doExport = async () => {
    try {
      await exportPdf(reportElement.current, elementName, props.tracked.createdInvoice, "chart", "portrait");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Accordion.Item title={elementName} className="hover:bg-gray-100 mt-4" value={props.id} ref={reportElement}>
      <Accordion.Control className={`relative v-tablet:h-[154px] h-[96px]`}>
        <div className="inset-4 text-xs grid grid-cols-2 justify-between">
          <div>
            <p className={headerClasses}>Invoice Amount</p>
            <div className="text-black font-[400] flex flex-row gap-2 items-baseline">
              <p className="text-2xl font-[400] pt-[2px] font-serif">
                {Language.FormatMoney(props.tracked.totalWholesaleFee)}
              </p>
              <p className={textHeaderClasses}>{props.tracked.agreementCurrency}</p>
            </div>
          </div>
          <div className="flex justify-between">
            <div>
              <p className={headerClasses}>Status</p>
              <div className="text-black font-[400] flex flex-row gap-2 items-baseline">
                <p className={textHeaderClasses + " pt-2"}>{status}</p>
              </div>
            </div>
            <div>
              <p className={headerClasses}>Company Name</p>
              <div className="text-black font-[400] flex flex-row gap-2 items-baseline">
                <p className={textHeaderClasses + " pt-2"}>{props.tracked.supplierName}</p>
              </div>
            </div>
            <div>
              <p className={headerClasses}>invoice month</p>
              <div className="text-black font-[400] flex flex-row gap-2 items-baseline">
                <p className={textHeaderClasses + " pt-2"}>{FormatDate(props.tracked.invoiceMonth, false, true)}</p>
              </div>
            </div>
          </div>
        </div>
      </Accordion.Control>
      <Accordion.Panel>
        <div className="grid grid-cols-5 pt-4 gap-4 border-t">
          <div className="flex col-span-2 grid grid-col-1 gap-4">
            {props.tracked.feePlans.map(value => (
              <div className="flex row-span-2 col-span-2">
                <ProductTrackerFeePlan
                  volumeSteps={value.feeSteps}
                  startDate={FormatDate(value.fromDate.toString())}
                  currency={props.tracked.agreementCurrency}
                  volumeLabel="Wholesale Volume"
                  feeLabel="Wholesale Fee %"
                />
              </div>
            ))}
          </div>
          <div className="grid flex grid-cols-3 col-span-3 ml-8">
            <div className="flex flex-col gap-4">
              <OverviewItem
                title="Total Wholesale"
                value={Language.FormatMoney(props.tracked.totalWholesale) + " " + props.tracked.agreementCurrency}
                loading={props.loading}
              ></OverviewItem>
            </div>
            <div className="flex flex-col gap-4">
              <OverviewItem
                title="Quantity"
                value={props.tracked.totalTrackedQuantity.toString()}
                loading={props.loading}
              ></OverviewItem>
            </div>
            <div className="my-4 flex flex-col">
              <p className="font-sans font-normal text-sc text-gray-600">STATUS</p>
              <Skeleton visible={props.loading} radius={0}>
                <CustomSelectElement
                  body={SelectBody({
                    paymentId: props.tracked.paymentId,
                    status: status,
                    setStatus: setStatus,
                  })}
                  open={isOpen}
                  setOpen={setOpen}
                  xAlign="left"
                >
                  <div className="flex justify-between items-center pr-1">
                    <p>{status}</p>
                    {isOpen ? <UpChevronIcon /> : <DownChevronIcon />}
                  </div>
                </CustomSelectElement>
              </Skeleton>
            </div>
            <div className="my-4 flex flex-col pdf-export-exclude">
              <p className="font-sans font-normal text-sc text-gray-600">BREAKDOWN</p>
              <div className="pt-2">
                <AsyncLinkDownload icon={<Download />} onClick={props.onClick} label="Download" />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <OverviewItem title="Organisation Nr" value={props.tracked.orgNr} loading={props.loading}></OverviewItem>
            </div>
            <div className="flex flex-col gap-4">
              <OverviewItem
                title="Retailers"
                value={props.tracked.retailerCount + " retailer(s)"}
                loading={props.loading}
              ></OverviewItem>
            </div>
            <div className="my-4 flex flex-col pdf-export-exclude">
              <p className="font-sans font-normal text-sc text-gray-600">EXPORT</p>
              <div className="pt-2">
                <AsyncLinkDownload icon={<Download />} onClick={() => doExport()} label="Export (.pdf)" />
              </div>
            </div>
          </div>
        </div>
      </Accordion.Panel>
    </Accordion.Item>
  );
}

function SelectBody(props: { paymentId: string; status: Status; setStatus: Dispatch<SetStateAction<Status>> }) {
  const [UpdateTrackedPaymentPost, { data: uploadData, isError: updateError, status: updateStatus, reset }] =
    useUpdateProductTrackerPaymentStatusMutation();
  const allStatuses = Object.values(Status).filter(value => typeof value === "string") as string[];

  return (
    <div className=" border border-gray-200 y-[100xp] w-[240px]">
      {allStatuses.map((value, index) => {
        return (
          <div
            key={index}
            className="p-2 hover:bg-gray-100"
            onClick={() => {
              props.setStatus(value as any);
              UpdateTrackedPaymentPost({ query: { paymentId: props.paymentId }, body: { Body: value } });
            }}
          >
            <p className="text-l truncate hover:cursor-pointer">{value}</p>
          </div>
        );
      })}
    </div>
  );
}

function OverviewItem(props: { title: string; value: string; loading?: boolean }) {
  return (
    <div className="flex flex-col my-4">
      <p className="font-sans font-normal text-sc text-gray-600">{props.title.toUpperCase()}</p>
      <p className="pt-2 text-M font-[600]">{props.value}</p>
    </div>
  );
}

function PayoutHeader(props: { setDateSpan: (value: [Date, Date]) => void; dateSpan: [Date, Date] }) {
  return (
    <div className="flex flex-col gap-2 v-tablet:flex-col">
      <div>
        <CalenderButton
          dateSpan={props.dateSpan}
          setDateSpan={props.setDateSpan}
          boxSize="v-tablet:w-full w-[240px] h-[32px]"
        />
      </div>
    </div>
  );
}
