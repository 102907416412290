const { REACT_APP_BASE_URL, REACT_APP_STAGING_BASE_URL, REACT_APP_INTERCOM_ID } = process.env;
const { REACT_APP_DEFAULT_LANGUAGE } = process.env;
const { REACT_APP_AUTH_PROVIDER } = process.env;
const { REACT_APP_VERSION } = process.env;
const { NODE_ENV } = process.env;

function getBaseUrl() {
  if (document.location.href.includes("-staging")) {
    return REACT_APP_STAGING_BASE_URL;
  }

  return REACT_APP_BASE_URL;
}

export class GlobalAppsettings {
  static IsDevelopment: boolean = NODE_ENV !== "production";

  static BaseUrl: string = getBaseUrl() ?? "";

  static InterCom: string = REACT_APP_INTERCOM_ID ?? "";

  static AuthProvider: string = REACT_APP_AUTH_PROVIDER ? REACT_APP_AUTH_PROVIDER : "auth0";

  static DefaultLang: string = REACT_APP_DEFAULT_LANGUAGE ? REACT_APP_DEFAULT_LANGUAGE : "en-GB";

  static Version: string = REACT_APP_VERSION ? REACT_APP_VERSION : "1.0";

  static Audience: string = `https://api.portal-admin.impulso.com`;

  static LogoutUri: string = this.IsDevelopment
    ? window.location.protocol + "//" + window.location.host
    : "https://www.impulso.se";
}
