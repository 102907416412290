import { NavigationSidebarView } from "@impulso/common/components/NavigationSidebarView";
import { INavigationConfig } from "@impulso/common/configuration/Navigation";
import Folder from "@impulso/common/Icons/Folder";
import Home from "@impulso/common/Icons/Home";
import ProductTracker from "@impulso/common/Icons/ProductTracker";
import Paths from "src/configuration/Paths";
import CalenderIcon from "@impulso/common/Icons/CalenderIcon";

export type NavigationSidebarProps = {
  onLogoutClick: () => void;
  isTablet?: boolean;
};

export default function NavigationSidebar(props: NavigationSidebarProps) {
  const navigationConfig = useNavigationConfig();

  return (
    <NavigationSidebarView
      config={navigationConfig}
      counters={{}}
      onLogoutClick={props.onLogoutClick}
      isTablet={false}
      logoColor="#fc6900"
    />
  );
}

export function useNavigationConfig(): INavigationConfig {
  return navigationConfig;
}

const navigationConfig: INavigationConfig = [
  {
    id: "home",
    links: [
      {
        path: "/",
        text: "navigation.dashboard",
        icon: <Home />,
      },
      {
        path: Paths.productTracker.trackedWholesaleReport,
        text: "productTracker.title",
        icon: <ProductTracker />,
        links: [
          {
            path: Paths.productTracker.trackedWholesaleReport,
            text: "trackedWholesaleReport.title",
          },
          {
            path: Paths.productTracker.trackedInvoice,
            text: "trackedInvoice.title",
          },
        ],
      },
      {
        path: Paths.autoPayment.autoPaymentReport,
        text: "autoPaymentReport.title",
        icon: <CalenderIcon />,
        links: [
          {
            path: Paths.autoPayment.autoPaymentReport,
            text: "autoPaymentReport.report",
          },
          {
            path: Paths.autoPayment.payoutReport,
            text: "payout.title",
          },
        ],
      },
      {
        path: Paths.agreement.agreements,
        text: "agreements.title",
        icon: <Folder />,
      },
    ],
  },
];
