type arrowProps = {
  width?: string | "16";
  height?: string | "16";
};

export default function ArrowDown(props: arrowProps) {
  return (
    <svg width={props.width} height={props.height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 7L11.5154 16.7596C11.5775 16.8355 11.6526 16.896 11.736 16.9373C11.8193 16.9787 11.9092 17 12 17C12.0908 17 12.1807 16.9787 12.264 16.9373C12.3474 16.896 12.4225 16.8355 12.4846 16.7596L21 7"
        stroke="currentcolor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
