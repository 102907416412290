import { OnClickOutside } from "@impulso/common/common/utilities/OnClickoutside";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import { Loader, TextInput } from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import { OrganisationId } from "src/UserProfile";
import { SecondaryButton } from "@impulso/common/components/buttons/SecondaryButton";
import { useDebouncedValue } from "@mantine/hooks";
import { useSearchOrganisationQuery } from "src/api/OrganisationApi";

export interface SearchProps {
  label: string;
  fetchOrganisation: (label: string, value: OrganisationId, currency?: string) => void;
  clearSearch?: () => void;
  currentOrg?: string;
  disabled?: boolean;
  isSupplier?: boolean;
  required?: boolean;
}

export default function SearchOrganisationField(props: SearchProps) {
  const [search, setSearch] = useState<string>("");
  const [debouncedSearch] = useDebouncedValue(search, 500);
  const [open, setOpen] = useState<boolean>(false);
  const wrapperRef = useRef(null);

  const {
    data,
    isFetching,
    isError: searchError,
  } = useSearchOrganisationQuery(
    { query: { search: encodeURIComponent(debouncedSearch), isSupplier: props.isSupplier } },
    { skip: debouncedSearch === "" },
  );

  useEffect(() => {
    if (!open) setOpen((search?.length ?? 0) > 0);
  }, [open, search]);

  OnClickOutside(
    wrapperRef,
    useCallback(() => {
      setOpen(false);
      setSearch("");
    }, []),
  );

  const sorted = data?.content?.map((o: any) => o).sort((a: any, b: any) => a.label.localeCompare(b.label));

  return (
    <div className="relative w-full" ref={wrapperRef}>
      <div className="flex gap-4 items-end">
        <TextInput
          className="min-w-[250px] grow"
          disabled={props.disabled || props.currentOrg ? true : false}
          label={
            <p>
              {props.label} {props.required ? <span className="text-error">*</span> : ""}
            </p>
          }
          styles={dropdownStyling}
          placeholder="Organisation Name..."
          value={props.currentOrg?.length == 0 ? search : props.currentOrg}
          onChange={event => setSearch(event.currentTarget.value)}
        />
        <SecondaryButton
          label="Clear"
          extraStyle="h-fit"
          type="button"
          disabled={!props.disabled && props.currentOrg ? false : true}
          onClick={props.clearSearch}
        />
      </div>
      <ResultsList
        isLoading={isFetching || debouncedSearch !== search}
        open={open}
        onClickCallback={(label: string, value: string, currency: string) => {
          setOpen(false);
          setSearch("");
          props.fetchOrganisation(label, value as OrganisationId, currency);
        }}
        results={sorted}
      />
    </div>
  );
}

function ResultsList(props: {
  results: { label: string; value: string; currency: string }[];
  open: boolean;
  onClickCallback: (label: string, value: string, currency: string) => void;
  isLoading?: boolean;
}) {
  return (
    <>
      {props.open && props.results !== undefined && (
        <div
          className={
            "absolute max-h-[400px] left-0 right-[81px] origin-top bg-white border border-gray-400 border-t-0 z-20 text-sm " +
            (props.isLoading ? "overflow-y-hidden" : "overflow-y-scroll")
          }
        >
          {props.isLoading && (
            <div className="absolute flex inset-0 bg-gray-100 opacity-50 items-center justify-center">
              <Loader className="p-2" />
              Loading...
            </div>
          )}
          {props.results.length === 0 && (
            <div className="flex p-2 h-9 justify-center">
              <p>{props.isLoading ? " " : "No Results"}</p>
            </div>
          )}
          {props.results.map(r => (
            <OrganisationListItem
              key={r.value}
              label={r.label}
              value={r.value}
              currency={r.currency}
              onClickCallback={props.onClickCallback}
            />
          ))}
        </div>
      )}
    </>
  );
}

function OrganisationListItem(props: {
  label: string;
  value: string;
  currency: string;
  onClickCallback: (label: string, value: string, currency: string) => void;
}) {
  return (
    <div
      onClick={() => props.onClickCallback(props.label, props.value, props.currency)}
      className="p-2 px-4 hover:cursor-pointer hover:bg-brand-100 truncate"
    >
      {props.label}
    </div>
  );
}
