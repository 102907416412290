import React, { Fragment, ReactNode, isValidElement, useState } from "react";
import { INavigationConfig } from "../configuration/Navigation";
import { Trans, useTranslation } from "react-i18next";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { Divider, Indicator, Navbar, UnstyledButton } from "@mantine/core";
import { MainLogo } from "../logos/MainLogo";
import ArrowLeft from "../Icons/ArrowLeft";
import ImpulsoSmall from "../Icons/ImpulsoSmall";
import ArrowUp from "../Icons/ArrowUp";
import ArrowDown from "../Icons/ArrowDown";

type Counter = string;

export type NavigationSidebarViewProps = {
  config: INavigationConfig;
  onLogoutClick: () => void;
  counters: Record<Counter, number | boolean | undefined>;
  isTablet: boolean;
  logoColor: string;
  impulsoVersion?: string;
};

export function NavigationSidebarView(props: NavigationSidebarViewProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname: currentPath } = useMatch("/*") ?? { pathname: null };

  const sections: Omit<SectionProps, "first">[] = props.config
    .map(section => ({
      key: section.id,
      links: section.links.map(link => ({
        onClick: () => {
          if (link.external) {
            window.open(link.path, "_blank");
          } else {
            navigate(link.path);
          }
        },
        onClickAction: (value: boolean) => {},
        key: link.text,
        text: t(link.text),
        icon: link.icon,
        notice: link.counter ? props.counters[link.counter] : undefined,
        active: currentPath === link.path,
        open: currentPath?.startsWith(link.path),
        links: link.links?.map(subLink => ({
          onClick: () => navigate(subLink.path),
          onClickAction: (value: boolean) => {},
          key: subLink.text,
          text: t(subLink.text),
          active: currentPath === subLink.path,
        })),
      })),
    }))
    .filter(s => s.links.length > 0); // Remove empty sections

  return props.isTablet ? (
    <TabletView
      impulsoVersion={props.impulsoVersion}
      logoColor={props.logoColor}
      sections={sections}
      onLogoutClick={props.onLogoutClick}
    />
  ) : (
    <DesktopView
      impulsoVersion={props.impulsoVersion}
      logoColor={props.logoColor}
      sections={sections}
      onLogoutClick={props.onLogoutClick}
    />
  );
}

function DesktopView(props: {
  impulsoVersion?: string;
  logoColor: string;
  sections: SectionProps[];
  onLogoutClick: () => void;
}) {
  return (
    <Navbar className="w-[236px] bg-gray-100 select-none shrink-0 flex flex-col p-6 ">
      <Navbar.Section>
        <Link to="/">
          <MainLogo width="119.56" height="32" color={props.logoColor} />
        </Link>
      </Navbar.Section>
      {props.sections.map((section, i) => {
        if (i === 0) {
          return <Section key={section.key} links={section.links} first />;
        }

        return (
          <Fragment key={section.key}>
            <Divider my="sm" />
            <Section key={section.key + "-section"} links={section.links} />
          </Fragment>
        );
      })}
      <Navbar.Section className="mt-auto">
        <UnstyledButton
          className="relative flex w-full transition-colors text-black hover:text-brand-900"
          onClick={() => props.onLogoutClick()}
        >
          <Trans i18nKey="ui.logout" />
        </UnstyledButton>
        {props.impulsoVersion && <p className="text-gray-400 text-sc">Version: {props.impulsoVersion}</p>}
      </Navbar.Section>
    </Navbar>
  );
}

function TabletView(props: {
  impulsoVersion?: string;
  logoColor: string;
  sections: SectionProps[];
  onLogoutClick: () => void;
}) {
  const [foldout, setFoldout] = useState(false);

  return (
    <div>
      <div className="min-w-[65px] max-w-[65px] h-full bg-gray-100"></div>
      <Navbar
        className={
          "absolute left-0 top-0 bottom-0 w-[236px] bg-gray-100 select-none flex flex-col transition-all overflow-x-hidden " +
          (foldout ? "p-6 w-[236px]" : "w-[65px] items-center py-6")
        }
      >
        <Navbar.Section>
          {foldout ? (
            <div className="flex justify-between items-center min-h-[32px] min-w-[150px]">
              <Link to="/">
                <MainLogo width="119.56" height="32" color={props.logoColor} />
              </Link>
              <div className="hover:cursor-pointer" onClick={() => setFoldout(false)}>
                <ArrowLeft />
              </div>
            </div>
          ) : (
            <div className="hover:cursor-pointer" onClick={() => setFoldout(true)}>
              <ImpulsoSmall />
            </div>
          )}
        </Navbar.Section>
        <div className={foldout ? "w-[236px] overflow-x-hidden" : ""}>
          {props.sections.map((section, i) => {
            if (i === 0) {
              return (
                <DynamicSection
                  key={section.key}
                  links={section.links}
                  first
                  foldout={foldout}
                  onClickAction={setFoldout}
                />
              );
            }

            return (
              <Fragment key={section.key}>
                <Divider my="sm" w={foldout ? "100%" : "16px"} />
                <DynamicSection
                  key={section.key + "-section"}
                  links={section.links}
                  foldout={foldout}
                  onClickAction={setFoldout}
                />
              </Fragment>
            );
          })}
        </div>
        {foldout ? (
          <Navbar.Section className="mt-auto">
            <UnstyledButton
              className={"relative flex w-full transition-colors text-black hover:text-brand-900"}
              onClick={() => props.onLogoutClick()}
            >
              <Trans i18nKey="ui.logout" />
            </UnstyledButton>

            {props.impulsoVersion && <p className="text-gray-400 text-sc">Version: {props.impulsoVersion}</p>}
          </Navbar.Section>
        ) : (
          <></>
        )}
      </Navbar>
    </div>
  );
}

export type SectionProps = {
  key: string;
  links: PrimaryNavLinkProps[];
  first?: boolean;
  foldout?: boolean;
  onClickAction?: (value: boolean) => void;
};

function Section(props: SectionProps) {
  return (
    <Navbar.Section className={props.first ? "mt-6" : ""}>
      {props.links.map(({ key, ...link }) => (
        <PrimaryNavLink key={key} {...link} foldout />
      ))}
    </Navbar.Section>
  );
}

function DynamicSection(props: SectionProps) {
  return (
    <Navbar.Section className={props.first ? "mt-6" : ""}>
      {props.links.map(({ key, ...link }) => (
        <PrimaryNavLink
          key={key}
          {...link}
          onClick={() => link.onClick()}
          onClickAction={props.onClickAction ? props.onClickAction : (value: boolean) => {}}
          foldout={props.foldout}
        />
      ))}
    </Navbar.Section>
  );
}

export type PrimaryNavLinkProps = {
  key: string;
  text: string;
  icon: ReactNode;
  notice?: number | boolean;
  links?: SubNavLinkProps[];
  active?: boolean;
  open?: boolean;
  foldout?: boolean;
  onClick: () => void;
  onClickAction?: (value: boolean) => void;
};

function PrimaryNavLink(props: PrimaryNavLinkProps) {
  const hasChildren = (props.links ?? []).length > 0;
  const open = props.open && hasChildren;
  const active = props.active || open;

  if (!props.foldout)
    return (
      <UnstyledButton
        aria-label={props.text}
        className={navStyle(active)}
        onClick={() => {
          if (props.onClickAction) props.onClickAction(hasChildren);
          props.onClick();
        }}
      >
        <NavLinkIcon icon={props.icon} notice={props.notice} foldout={props.foldout} />
      </UnstyledButton>
    );

  return (
    <>
      <UnstyledButton
        aria-label={props.text}
        className={navStyle(active)}
        onClick={() => {
          if (props.onClickAction) props.onClickAction(hasChildren);
          props.onClick();
        }}
      >
        <NavLinkIcon icon={props.icon} notice={props.notice} foldout={props.foldout} />
        {props.text}
        <NavLinkCaretIcon visible={hasChildren} open={open} />
      </UnstyledButton>
      {open &&
        props.links?.map(({ key, ...subLink }) => (
          <SubNavLink key={key} {...subLink} onClickAction={props.onClickAction} />
        ))}
    </>
  );
}

type SubNavLinkProps = {
  key: string;
  text: string;
  active?: boolean;
  onClick: () => void;
  onClickAction?: (value: boolean) => void;
};

function SubNavLink(props: SubNavLinkProps) {
  return (
    <>
      <UnstyledButton
        aria-label={props.text}
        className={navStyle(props.active) + " hover:text-brand-900 pl-8 pt-0 pb-2 text-xs"}
        onClick={() => {
          if (props.onClickAction) props.onClickAction(false);
          props.onClick();
        }}
      >
        {props.text}
      </UnstyledButton>
    </>
  );
}

const navStyle = (active?: boolean) =>
  `relative block w-full py-2 transition-colors ${active ? "text-brand font-semibold" : "text-black"} hover:text-brand-900`;

export function NavLinkIcon(props: { icon?: ReactNode; notice?: number | boolean; foldout?: boolean; size?: string }) {
  if (!props.icon) {
    return null;
  }

  if (!props.notice) {
    return (
      <div className={"inline-block align-middle mb-1 " + (props.foldout ? "mr-2 ml-1" : "")}>
        <NavLinkIconInner icon={props.icon} size={props.size} />
      </div>
    );
  }

  const label = typeof props.notice === "number" ? (props.notice > 9 ? "9+" : props.notice.toFixed()) : undefined;

  return (
    <Indicator
      className="mr-2 ml-1 w-4 h-4 inline-block"
      label={label}
      size={16}
      radius="md"
      styles={{ indicator: { fontSize: "10px", fontWeight: "normal" } }}
    >
      <NavLinkIconInner icon={props.icon} size={props.size} />
    </Indicator>
  );
}

function NavLinkIconInner(props: { icon?: ReactNode; size?: string }) {
  if (!props.icon) {
    return null;
  }

  if (isValidElement(props.icon))
    return <div className={"flex align-center " + (props.size ? props.size : "")}>{props.icon}</div>;
  else return <>{props.icon}</>;
}

export function NavLinkCaretIcon(props: { visible?: boolean; open?: boolean }) {
  if (!props.visible) {
    return null;
  }

  return <div className="absolute left-40 bottom-3 w-4 h-4">{props.open ? <ArrowUp /> : <ArrowDown />}</div>;
}
