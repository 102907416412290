export const paginationStyling = {
  control: {
    borderRadius: 0,
    fontSize: "12px",
    "&[data-active]": {
      borderColor: "#000",
      borderWidth: 1,
      backgroundColor: "#fff",
      color: "#000",
    },
  },
};
