import { OrganisationId } from "src/UserProfile";
import { organisationApi } from "./OrganisationApi";

export const agreementEndpoints = organisationApi.injectEndpoints({
  endpoints: builder => ({
    getAgreements: builder.query<AgreementResponse[], {}>({
      query: () => ({
        url: `/api/agreement/GetAgreements`,
      }),
      providesTags: ["agreement"],
    }),
    getAgreement: builder.query<AgreementEndpointResponse, { orgId: OrganisationId }>({
      query: params => ({
        url: `/api/agreement/GetAgreement?organisationId=${params.orgId}`,
      }),
      providesTags: ["agreement"],
    }),
    createAgreement: builder.mutation<AgreementResponse, { body: FormData }>({
      query: ({ body }) => ({
        url: `/api/agreement/CreateAgreement`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["agreement"],
    }),
    updateAgreement: builder.mutation<AgreementEndpointResponse, { query: { orgId: OrganisationId }; body: FormData }>({
      query: params => ({
        url: `/api/agreement/UpdateImpulsoAgreement?orgId=${params.query.orgId}`,
        method: "POST",
        body: params.body,
      }),
      invalidatesTags: ["agreement"],
    }),
  }),
});

export interface AgreementEndpointResponse {
  content?: any;
  status: "FAILED" | "SUCCESS" | "NOT_FOUND";
  error: string;
}

export interface VolumeFeePlan {
  fromDate: Date;
  feeSteps: VolumeFeeStep[];
}
export interface VolumeFeeStep {
  fromVolume: number;
  wholesaleFee: number;
}

export interface VolumeFeePlanRequest {
  fromDate: string;
  feeSteps: VolumeFeeStep[];
}

export interface AgreementResponse {
  id: string;
  partnerName: string;
  partnerCurrency: string;
  startDate: string;
  endDate: string;
  hasAutoPayment: boolean;
  hasProductTracker: boolean;
  createdByUser: string;
  createdDate: string;
  hasNoosSplit: boolean;
  noosSplitMonths: number;
  brands: string[];
  volumeFeePlans?: VolumeFeePlanRequest[];
  discountFeePlan?: VolumeFeePlanRequest;
  autoPaymentFeePercent?: number;
  fileAttachments: FileAttachmentResponse[];
}

export interface CreateAgreementRequest {
  supplierId: string;
  startDate: string;
  endDate?: string;
  hasAutoPayment: boolean;
  hasProductTracker: boolean;
  volumeFeePlan?: VolumeFeePlan[];
  autoPaymentFeePercent?: number;
  formData: FormData;
}

export interface FileAttachmentResponse {
  fileName: string;
  fileId: string;
}

export const { useGetAgreementsQuery, useGetAgreementQuery, useCreateAgreementMutation, useUpdateAgreementMutation } =
  agreementEndpoints;
