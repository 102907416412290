export default function UpChevronIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 11L7.67692 5.14427C7.71836 5.09873 7.76841 5.06243 7.82397 5.03761C7.87954 5.0128 7.93945 5 8 5C8.06055 5 8.12046 5.0128 8.17603 5.03761C8.23159 5.06243 8.28164 5.09873 8.32308 5.14427L14 11"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
