import { Loader } from "@mantine/core";
import React from "react";

export interface ButtonProps {
  loading?: boolean;
  label: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  textSize?: "text-xs" | "text-sm" | "text-l" | "text-xl" | "text-md" | undefined;
  padding?: string;
  margin?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
  extraStyle?: string;
  onClick?: () => void;
}

export function PrimaryButton(props: ButtonProps) {
  const style = `${props.extraStyle ?? ""} flex w-full h-fit gap-2 items-center rounded-full justify-center select-none relative whitespace-nowrap flex-nowrap`;
  const activeStyle = "cursor-pointer bg-black text-white hover:bg-brand active:bg-brand-900 active:translate-y-[1px]";
  const disabledStyle = "bg-gray-400 text-gray-200";
  const disabled = props.disabled || props.loading;

  return (
    <button
      id={props.label.replace(" ", "_").toLowerCase()}
      disabled={disabled}
      type={props.type ?? "button"}
      onClick={props.onClick}
      className={`${style} ${disabled ? disabledStyle : activeStyle} ${props.textSize ?? "text-l font-normal "} ${props.padding ?? "p-2 px-4"} ${props.margin}`}
    >
      <Loader
        size="sm"
        className={`${props.loading ? "visible" : "invisible"} absolute left-[50%] translate-x-[-50%]`}
      />
      <div
        className={`${props.loading ? "opacity-0" : "opacity-100"} overflow-auto flex items-center gap-2 whitespace-nowrap`}
      >
        {props.leftIcon}
        <p className={"truncate"}>{props.label}</p>
        {props.rightIcon}
      </div>
    </button>
  );
}
