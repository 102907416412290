export const colors = {
  brand: {
    DEFAULT: "#fc6900",
    alternative: "#f26f41",
    50: "#FFF3EB",
    100: "#FFF3EB",
    200: "#FFDBC2",
    300: "#FFBF92",
    400: "#FFA261",
    500: "#FF8631",
    600: "#FF6900",
    700: "#EA6100",
    800: "#D45800",
    900: "#A94600",
  },
  gray: {
    100: "#F5F5F5",
    200: "#EFEFEF",
    300: "#E9E9E9",
    400: "#DCDCDC",
    500: "#BEBEBE",
    600: "#A0A0A0",
    700: "#5A5A5A",
    800: "#373737",
    900: "#141414",
  },
  confirmation: {
    DEFAULT: "#1BB663",
    100: "#EDFCF4",
    600: "#1BB663",
    900: "#0B4727",
  },
  warning: {
    DEFAULT: "#F8D05A",
    100: "#FBF3DA",
    600: "#F8D05A",
    900: "#372B06",
  },
  error: {
    DEFAULT: "#E52424",
    100: "#FCE5E5",
    600: "#E52424",
    900: "#380606",
  },
  white: "#FFFFFF",
  black: "#000000",
  red: "#991B1B",
  yellow: "#D97706",
  green: "#059669",
  blue: "#1E40AF",
  indigo: "#818CF8",
  purple: "#A78BFA",
  pink: "#EC4899",
} as const;
