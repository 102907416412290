import { AdminPayoutResponse } from "src/modules/payouts/api/payout-api";
import { organisationApi } from "./OrganisationApi";

export const payoutApi = organisationApi.injectEndpoints({
  endpoints: builder => ({
    getAdminPayouts: builder.query<AdminPayoutResponse[], { startDate: string; endDate: string }>({
      query: params => `/api/payouts/GetAdminPayouts?startDate=${params.startDate}&endDate=${params.endDate}`,
    }),
  }),
});

export const { useGetAdminPayoutsQuery } = payoutApi;
