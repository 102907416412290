export default function ArrowRight() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 2L10.8557 7.67692C10.9013 7.71836 10.9376 7.76841 10.9624 7.82397C10.9872 7.87954 11 7.93945 11 8C11 8.06055 10.9872 8.12046 10.9624 8.17603C10.9376 8.23159 10.9013 8.28164 10.8557 8.32308L5 14"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
