import { Loader } from "@mantine/core";
import { ButtonProps } from "./PrimaryButton";
import React from "react";

export function SecondaryButton(props: ButtonProps) {
  const style = `${props.extraStyle ?? ""} flex gap-2 items-center rounded-full justify-center select-none relative border`;
  const activeStyle =
    "cursor-pointer border-black box-border bg-white text-black hover:text-white active:text-white hover:bg-brand hover:border-brand active:bg-brand-900 active:border-brand-900 active:translate-y-[1px] ";
  const disabledStyle = "bg-white text-gray-900";
  const disabled = props.disabled || props.loading;

  return (
    <>
      <button
        disabled={disabled}
        type={props.type}
        onClick={props.onClick}
        className={`${style} ${disabled ? disabledStyle : activeStyle} ${props.textSize ?? "text-sm"} ${props.padding ?? "p-[7px] px-[14px]"} ${props.margin}`}
      >
        <Loader
          size="sm"
          className={`${props.loading ? "visible" : "invisible"} absolute left-[50%] translate-x-[-50%]`}
        />
        <div className={`${props.loading ? "opacity-0" : "opacity-100"} flex items-center gap-2`}>
          {props.leftIcon}
          <p>{props.label}</p>
          {props.rightIcon}
        </div>
      </button>
    </>
  );
}
