import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { DateInputField } from "@impulso/common/components/inputs/dateInputField";
import Plus from "@impulso/common/Icons/Plus";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import { Checkbox, Loader, NumberInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { addDays, addMonths } from "date-fns";
import { format } from "date-fns/format";
import { parse } from "date-fns/parse";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AgreementResponse,
  VolumeFeePlanRequest,
  useGetAgreementQuery,
  useUpdateAgreementMutation,
} from "src/api/AgreementApi";
import Paths from "src/configuration/Paths";
import { AttachmentSection } from "src/pages/agreements/AttachmentSection";
import { ProductTrackerPanel } from "src/pages/agreements/CreateAgreement";
import { OrganisationId } from "src/UserProfile";
import { CreateBanner } from "../EndpointBanner";
import ReportFoldout from "../ReportFoldout";
import SearchOrganisationField from "./SearchOrganisation";

const initialValues = {
  id: "",
  createdByUser: "",
  createdDate: "",
  endDate: "",
  fileAttachments: [],
  hasAutoPayment: false,
  hasProductTracker: false,
  hasProductTrackerDiscount: false,
  partnerCurrency: "SEK",
  partnerName: "",
  startDate: "",
  autoPaymentFeePercent: 3,
  hasNoosSplit: false,
  noosSplitMonths: 0,
  volumeFeePlans: [],
  brands: [],
  discountFeePlan: undefined,
} as AgreementResponse;

export default function UpdateImpulsoAgreementPanel() {
  const [search, setSearch] = useState<{ label: string; value: OrganisationId } | undefined>(undefined);
  const navigate = useNavigate();
  const [hasProductTrackerDiscount, setHasProductTrackerDiscount] = useState(false);
  const [discountEndDate, setDiscountEndDate] = useState(new Date());
  const [attachments, setAttachments] = useState<File[] | undefined>(undefined);

  const today = new Date();

  const [UpdateAgreementPost, { data: uploadData, isError: updateError, status: updateStatus }] =
    useUpdateAgreementMutation();
  const { data: agreementResult, status: agreementStatus } = useGetAgreementQuery(
    { orgId: search?.value ?? ("" as OrganisationId) },
    { skip: !search },
  );

  const form = useForm<AgreementResponse>({
    validateInputOnBlur: true,
    initialValues,
  });

  useEffect(() => {
    if (agreementResult?.content != null && agreementStatus === QueryStatus.fulfilled) {
      //we don't want to set any form value to undefined because it will give us a warning
      const agreementData: AgreementResponse = Object.keys(agreementResult.content).reduce((cur: any, sKey) => {
        const key: keyof AgreementResponse = sKey as keyof AgreementResponse;
        cur[key] = agreementResult.content[key] ?? initialValues[key];
        return cur;
      }, {} as AgreementResponse);
      form.setValues({ ...agreementData });
      setHasProductTrackerDiscount(agreementData.discountFeePlan != null);
      console.log(agreementData.discountFeePlan);
      const initialDiscountEndDate =
        agreementData.discountFeePlan === undefined
          ? addMonths(parse(agreementData.startDate, "yyyy-MM-dd", today), 1)
          : addDays(parse(agreementData.volumeFeePlans![0].fromDate, "yyyy-MM-dd", today), -1);
      setDiscountEndDate(initialDiscountEndDate);
    }
  }, [agreementResult, agreementStatus]);

  const agreement = form.values;
  const loading = agreementStatus === "pending";
  const initializing = agreement.id === "" && agreementResult?.status === "SUCCESS";
  const startDate = parse(agreement.startDate, "yyyy-MM-dd", today);
  const endDate = parse(agreement.endDate, "yyyy-MM-dd", today);

  function sendForm() {
    const formData = new FormData();
    formData.set("agreementId", agreement.id);
    formData.set("supplierId", search!.value);
    formData.set("startDate", format(startDate, "yyyy-MM-dd"));
    formData.set("endDate", format(endDate, "yyyy-MM-dd"));

    const volumeFee = !agreement.hasProductTracker ? [] : agreement.volumeFeePlans;
    formData.set("volumeFeePlans", JSON.stringify(volumeFee));

    if (hasProductTrackerDiscount) {
      formData.set("discountFeePlan", JSON.stringify(agreement.discountFeePlan));
      formData.set("discountEndDate", format(discountEndDate, "yyyy-MM-dd"));
    }

    formData.set("hasAutoPayment", String(agreement.hasAutoPayment));
    formData.set("hasProductTracker", String(agreement.hasProductTracker));
    formData.set("hasNoosSplit", String(agreement.hasNoosSplit));
    formData.set("noosSplitMonths", String(agreement.noosSplitMonths));

    if (agreement.hasAutoPayment) {
      formData.set("autoPaymentFeePercent", agreement.autoPaymentFeePercent!.toString());
    }

    (attachments ?? []).forEach(a => {
      formData.append("fileAttachments", a);
    });

    UpdateAgreementPost({ query: { orgId: search?.value ?? ("" as OrganisationId) }, body: formData });
  }

  return (
    <ReportFoldout title="Update Impulso Agreement">
      <div className="">
        <div className="w-[50%] px-4 pt-4">
          <SearchOrganisationField
            label="Organisation"
            currentOrg={search?.label ?? ""}
            clearSearch={() => {
              setSearch(undefined);
            }}
            fetchOrganisation={(label: string, value: OrganisationId) => {
              setSearch({ label, value });
            }}
          />
        </div>

        <form
          className="py-4 mt-4 px-4 relative border-t"
          onSubmit={e => {
            e.preventDefault();
            sendForm();
            setAttachments(undefined);
          }}
        >
          {!loading && (
            <>
              {search && agreementResult?.status === "NOT_FOUND" && (
                <>
                  <Title weight={400}>Whoops!</Title>
                  <p className="mb-4">This organisation does not seem to have an Impulso Agreement?</p>
                  <PrimaryButton
                    label="Create Agreement"
                    rightIcon={<Plus />}
                    extraStyle="w-min !absolute bottom-4"
                    onClick={() => navigate(Paths.agreement.create)}
                  />
                </>
              )}
              {search && !initializing && agreementResult?.status === "SUCCESS" && (
                <div className="mb-4 flex flex-col gap-4">
                  <div className="flex justify-between">
                    <p className={"text-S text-gray-500"}>ID: {agreement.id}</p>
                    <p className={"text-S "}>
                      Created <span className="font-semibold">{agreement.createdDate}</span> by{" "}
                      <span className="font-semibold">{agreement.createdByUser}</span>
                    </p>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <DateInputField
                      label="Start Date"
                      size="sm"
                      placeholder="Enter Date..."
                      today={today}
                      value={startDate}
                      onChange={date => {
                        form.setFieldValue("startDate", format(date!, "yyyy-MM-dd"));
                      }}
                    />
                    <DateInputField
                      label="End Date"
                      size="sm"
                      placeholder="Enter Date..."
                      today={today}
                      value={endDate}
                      onChange={date => {
                        form.setFieldValue("endDate", format(date!, "yyyy-MM-dd"));
                      }}
                    />
                  </div>

                  <AutoPaymentPanel
                    checkInputs={{ ...form.getInputProps("hasAutoPayment", { type: "checkbox" }) }}
                    percentInputs={{ ...form.getInputProps("autoPaymentFeePercent") }}
                  />

                  <ProductTrackerPanel
                    feeList={[...(agreement.volumeFeePlans?.at(0)?.feeSteps ?? [])]}
                    hasProductTracker={agreement.hasProductTracker}
                    setFeeList={value =>
                      form.setFieldValue("volumeFeePlans", [
                        {
                          fromDate: hasProductTrackerDiscount
                            ? format(discountEndDate, "yyyy-MM-dd")
                            : format(startDate, "yyyy-MM-dd"),
                          feeSteps: value,
                        },
                      ] as VolumeFeePlanRequest[])
                    }
                    setHasProductTracker={value => form.setFieldValue("hasProductTracker", value)}
                    hasNoosSplit={agreement.hasNoosSplit}
                    setNoosSplit={value => form.setFieldValue("hasNoosSplit", value)}
                    hasNoosSplitMonths={agreement.noosSplitMonths ?? 0}
                    setNoosSplitMonths={(value: number) => form.setFieldValue("noosSplitMonths", value)}
                    supplier={{
                      label: agreement.partnerName,
                      currency: agreement.partnerCurrency,
                      value: "",
                    }}
                    discountFeeList={[...(agreement.discountFeePlan?.feeSteps ?? [])]}
                    hasDiscount={hasProductTrackerDiscount}
                    setDiscountFeeList={value =>
                      form.setFieldValue("discountFeePlan", {
                        fromDate: format(startDate, "yyyy-MM-dd"),
                        feeSteps: value,
                      } as VolumeFeePlanRequest)
                    }
                    setHasDiscount={setHasProductTrackerDiscount}
                    today={today}
                    discountStartDate={startDate}
                    discountEndDate={discountEndDate}
                    setDiscountEndDate={value => setDiscountEndDate(value)}
                  />
                  <div className="grid grid-cols-2 h-tablet:grid-cols-1 gap-y-8">
                    <div className="ml-4 flex flex-col">
                      <p className="pb-1">Existing Attachments</p>
                      {agreement.fileAttachments.map(fa => (
                        <div className="text-S pl-3 ml-1 border-l py-1">{fa.fileName}</div>
                      ))}
                    </div>
                    <AttachmentSection attachments={attachments ?? []} setAttachments={setAttachments} />
                  </div>
                </div>
              )}
              <div className="flex justify-between gap-4">
                <PrimaryButton
                  label="Update"
                  disabled={agreementResult?.status !== "SUCCESS" || !search}
                  extraStyle="w-min"
                  margin="ml-auto"
                  padding="py-2 px-8"
                  type="submit"
                />
              </div>
            </>
          )}
          {loading && <div className="absolute inset-0 bg-white opacity-50" />}
          {loading && (
            <div className="absolute flex inset-0 items-center justify-center">
              <Loader className="p-2" />
              Loading...
            </div>
          )}
        </form>
      </div>
      {updateStatus !== QueryStatus.uninitialized && (
        <CreateBanner data={uploadData} status={updateStatus} isError={updateError} />
      )}
    </ReportFoldout>
  );
}

interface FormInputProps {
  value: any;
  onChange: any;
  checked?: any;
  error?: any;
  onFocus?: any;
  onBlur?: any;
}

function AutoPaymentPanel(props: { checkInputs: FormInputProps; percentInputs: FormInputProps }) {
  return (
    <div className="border p-4 -mt-2">
      <Checkbox label="Auto Payment" styles={dropdownStyling} {...props.checkInputs} />
      <div className={props.checkInputs.checked ? "pt-2" : "hidden"}>
        <NumberInput
          label="Auto Payment Fee"
          styles={dropdownStyling}
          min={0}
          max={100}
          step={0.05}
          precision={2}
          {...props.percentInputs}
          parser={value => value.replace("%", "")}
          formatter={value => (!Number.isNaN(parseFloat(value)) ? `${value}%` : "")}
          stepHoldDelay={500}
          stepHoldInterval={100}
        />
      </div>
    </div>
  );
}
