import React from "react";

export default function DownChevronIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 5L7.67692 10.8557C7.71836 10.9013 7.76841 10.9376 7.82397 10.9624C7.87954 10.9872 7.93945 11 8 11C8.06055 11 8.12046 10.9872 8.17603 10.9624C8.23159 10.9376 8.28164 10.9013 8.32308 10.8557L14 5"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
