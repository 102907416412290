import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { SecondaryButton } from "@impulso/common/components/buttons/SecondaryButton";
import { Page } from "@impulso/common/components/Page";
import ArrowLeft from "@impulso/common/Icons/ArrowLeft";
import Minus from "@impulso/common/Icons/Minus";
import Plus from "@impulso/common/Icons/Plus";
import { DateInputField } from "@impulso/common/src/components/inputs/dateInputField";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import { Checkbox, NumberInput } from "@mantine/core";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { addMonths, addYears } from "date-fns";
import { addDays } from "date-fns/addDays";
import { format } from "date-fns/format";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCreateAgreementMutation, VolumeFeeStep } from "src/api/AgreementApi";
import Paths from "src/configuration/Paths";
import { CreateBanner } from "src/modules/EndpointBanner";
import SearchOrganisationField from "src/modules/organisations/SearchOrganisation";
import { OrganisationId } from "src/UserProfile";
import { AttachmentSection } from "./AttachmentSection";

export const filetypeAccept = {
  "application/msword": [".doc"],
  "application/pdf": [".pdf"],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "image/*": [".png", ".jpg", ".jpeg"],
  "text/plain": [".txt"],
};

export function CreateAgreement() {
  const today = new Date();
  const [supplier, setSupplier] = useState<{ label: string; value: string; currency: string } | undefined>(undefined);
  const [startDate, setStartDate] = useState(addDays(today, 1));
  const [endDate, setEndDate] = useState(addYears(today, 1));
  const [discountEndDate, setDiscountEndDate] = useState(addMonths(today, 1));
  const [hasProductTracker, setHasProductTracker] = useState(false);
  const [hasNoosSplit, setHasNoosSplit] = useState(false);
  const [hasNoosSplitMonths, setHasNoosSplitMonths] = useState<number>(0);
  const [hasProductTrackerDiscount, setHasProductTrackerDiscount] = useState(false);
  const [hasAutoPayment, setHasAutoPayment] = useState(false);
  const [autoPaymentFee, setAutoPaymentFee] = useState<number | undefined>(3);
  const [attachments, setAttachments] = useState<File[]>([]);
  const navigate = useNavigate();

  const [CreateAgreement, { status, isError, error }] = useCreateAgreementMutation();

  const [feeList, setFeeList] = useState<VolumeFeeStep[]>([{ fromVolume: 0, wholesaleFee: 3 }]);
  const [discountFeeList, setDiscountFeeList] = useState<VolumeFeeStep[]>([{ fromVolume: 0, wholesaleFee: 3 }]);

  useEffect(() => {
    if (!isError && status === QueryStatus.fulfilled) {
      setFeeList([{ fromVolume: 0, wholesaleFee: 3 }]);
      setDiscountFeeList([{ fromVolume: 0, wholesaleFee: 3 }]);
    }
  }, [isError, status]);

  function clearSupplier() {
    setSupplier(undefined);
  }

  function fetchSupplier(label: string, value: OrganisationId, currency?: string) {
    setSupplier({ label, value, currency: currency ?? "nil" });
  }

  async function sendForm() {
    const formData = new FormData();

    formData.set("supplierId", supplier!.value);
    formData.set("startDate", format(startDate, "yyyy-MM-dd"));
    formData.set("endDate", format(endDate, "yyyy-MM-dd"));

    const volumeFee = !hasProductTracker
      ? []
      : feeList.map(item => ({ fromVolume: item.fromVolume, wholesaleFee: item.wholesaleFee }));
    const discountVolumeFee = !hasProductTrackerDiscount
      ? []
      : discountFeeList.map(item => ({ fromVolume: item.fromVolume, wholesaleFee: item.wholesaleFee }));

    formData.set("volumeFee", JSON.stringify(volumeFee));
    formData.set("discountVolumeFee", JSON.stringify(discountVolumeFee));

    if (hasProductTrackerDiscount) {
      formData.set("discountEndDate", format(discountEndDate, "yyyy-MM-dd"));
    }

    formData.set("hasAutoPayment", String(hasAutoPayment));
    formData.set("hasProductTracker", String(hasProductTracker));

    if (hasAutoPayment) {
      formData.set("autoPaymentFeePercent", autoPaymentFee!.toString());
    }

    attachments.forEach(a => {
      formData.append("fileAttachments", a);
    });

    CreateAgreement({
      body: formData,
    });
  }

  const backButton = () => {
    return (
      <SecondaryButton
        label="Back"
        margin="mr-4 ml-4 mt-1"
        leftIcon={<ArrowLeft />}
        onClick={() => navigate(Paths.agreement.agreements)}
      />
    );
  };

  return (
    <Page hasAccess={true} titleKey="agreements.create.title" leftAction={backButton()} responsive>
      <form
        className="grid grid-cols-2 mt-4 gap-4"
        onSubmit={async e => {
          e.preventDefault();
          await sendForm();
        }}
      >
        <div className="border flex flex-col col-span-2">
          <div className="flex flex-col gap-y-4 p-4 pt-2">
            <SearchOrganisationField
              label="Supplier"
              required
              currentOrg={supplier?.label ?? ""}
              clearSearch={clearSupplier}
              fetchOrganisation={fetchSupplier}
            />
            <div className="grid grid-cols-2 gap-4">
              <div>
                <DateInputField
                  label="Agreement start date"
                  size="sm"
                  placeholder="Agreement start date"
                  today={today}
                  value={startDate}
                  onChange={value => setStartDate(value ?? today)}
                  required
                />
              </div>
              <div>
                <DateInputField
                  label="Agreement end date"
                  size="sm"
                  placeholder="Agreement end date"
                  today={today}
                  value={endDate}
                  onChange={value => setEndDate(value ?? today)}
                  required
                />
              </div>
            </div>

            <AutoPaymentPanel
              autoPaymentFee={autoPaymentFee}
              hasAutoPayment={hasAutoPayment}
              setAutoPaymentFee={setAutoPaymentFee}
              setHasAutoPayment={setHasAutoPayment}
            />
            <ProductTrackerPanel
              feeList={feeList}
              hasProductTracker={hasProductTracker}
              setFeeList={setFeeList}
              setHasProductTracker={setHasProductTracker}
              supplier={supplier}
              setDiscountFeeList={setDiscountFeeList}
              discountFeeList={discountFeeList}
              hasDiscount={hasProductTrackerDiscount}
              setHasDiscount={setHasProductTrackerDiscount}
              setNoosSplit={setHasNoosSplit}
              hasNoosSplit={hasNoosSplit}
              hasNoosSplitMonths={hasNoosSplitMonths}
              setNoosSplitMonths={setHasNoosSplitMonths}
              today={today}
              discountStartDate={startDate}
              discountEndDate={discountEndDate}
              setDiscountEndDate={setDiscountEndDate}
            />

            <AttachmentSection attachments={attachments} setAttachments={setAttachments} />
            <PrimaryButton label="Create" type="submit" disabled={supplier === undefined} />
          </div>
          <CreateBanner
            isError={isError}
            status={status}
            error={error}
            successMsg={`Successfully created agreement for organisation: ${supplier?.label}`}
          />
        </div>
      </form>
    </Page>
  );
}

interface Supplier {
  label: string;
  value: string;
  currency: string;
}

function FeeScalingList(props: {
  feeList: VolumeFeeStep[];
  setFeeList: (value: VolumeFeeStep[]) => void;
  currency: string;
  supplier: Supplier | undefined;
}) {
  function updateItem(item: VolumeFeeStep, index: number) {
    const list = props.feeList;
    list[index] = item;
    props.setFeeList([...list]);
  }

  function addItem() {
    if (props.supplier === undefined) return;
    const lastItem =
      props.feeList.length === 0 ? { fromVolume: -1000000, wholesaleFee: 3 } : props.feeList[props.feeList.length - 1];
    props.setFeeList([
      ...props.feeList,
      { fromVolume: lastItem.fromVolume + 1000000, wholesaleFee: lastItem.wholesaleFee },
    ]);
  }

  function removeItem() {
    if (props.feeList.length === 1) return;
    const list = props.feeList.filter((item, i) => i < props.feeList.length - 1);
    props.setFeeList([...list]);
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <p className="text-M">Tracked stock fee scaling</p>
        <div className="flex items-center border border-b-white z-10 -mb-[1px]">
          <div
            className={`hover:bg-gray-300 hover:cursor-pointer p-1 px-2 h-[32px] items-center flex`}
            onClick={addItem}
          >
            <Plus />
          </div>
          <div
            className={`${props.feeList.length <= 1 ? "text-gray-600" : "hover:bg-gray-300 hover:cursor-pointer"} p-1 px-2 flex h-[32px] items-center`}
            onClick={removeItem}
          >
            <Minus />
          </div>
        </div>
      </div>
      <div className="border p-4 grid grid-cols-3 gap-4 gap-y-2 items-end text-M text-gray-600">
        <p>Starting amount ({props.currency})</p>
        <p>End amount ({props.currency})</p>
        <p>Impulso fee (%)</p>
        {props.feeList.map((item, index) => {
          const prevAmount = index !== 0 ? props.feeList[index - 1].fromVolume + 1 : 0;
          const nextAmount = props.feeList.length - 1 > index ? props.feeList[index + 1].fromVolume : 0;
          return (
            <FeeLineItem
              key={index}
              index={index}
              item={item}
              prevAmount={prevAmount}
              nextAmount={nextAmount}
              firstItem={index === 0}
              updateItem={updateItem}
            />
          );
        })}
      </div>
    </div>
  );
}

function FeeLineItem(props: {
  item: VolumeFeeStep;
  prevAmount: number;
  nextAmount: number;
  firstItem: boolean;
  index: number;
  updateItem: (item: VolumeFeeStep, index: number) => void;
}) {
  return (
    <div className="col-span-3 grid grid-cols-3 gap-4">
      <NumberInput
        styles={dropdownStyling}
        value={props.item.fromVolume}
        parser={value => value.replace(/\$\s?|( *)/g, "")}
        formatter={value =>
          !Number.isNaN(parseFloat(value)) ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ") : ""
        }
        onChange={value =>
          props.updateItem(
            {
              ...props.item,
              fromVolume: value === "" ? 0 : value,
            },
            props.index,
          )
        }
        min={props.prevAmount}
        disabled={props.firstItem}
        hideControls
      />
      <NumberInput
        styles={dropdownStyling}
        value={props.nextAmount !== 0 ? props.nextAmount : ""}
        onChange={() => {}}
        parser={value => value.replace(/\$\s?|( *)/g, "")}
        formatter={value =>
          !Number.isNaN(parseFloat(value)) ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ") : "-"
        }
        min={props.prevAmount}
        hideControls
        disabled
      />
      <NumberInput
        styles={dropdownStyling}
        min={0}
        max={100}
        step={0.05}
        precision={5}
        value={props.item.wholesaleFee}
        onChange={value => props.updateItem({ ...props.item, wholesaleFee: value === "" ? 0 : value }, props.index)}
        parser={value => value.replace("%", "")}
        formatter={value => (!Number.isNaN(parseFloat(value)) ? `${value}%` : "")}
        stepHoldDelay={500}
        stepHoldInterval={100}
      />
    </div>
  );
}

interface AutoPaymentPanelProps {
  autoPaymentFee: number | undefined;
  hasAutoPayment: boolean;
  setAutoPaymentFee: (value: number | undefined) => void;
  setHasAutoPayment: (value: boolean) => void;
}

function AutoPaymentPanel({
  autoPaymentFee,
  hasAutoPayment,
  setAutoPaymentFee,
  setHasAutoPayment,
}: AutoPaymentPanelProps) {
  return (
    <div className="border p-4">
      <Checkbox
        label="Auto Payment"
        styles={dropdownStyling}
        checked={hasAutoPayment}
        onChange={e => setHasAutoPayment(e.currentTarget.checked)}
      />
      <div className={hasAutoPayment ? "pt-2" : "hidden"}>
        <NumberInput
          label="Auto Payment Fee"
          styles={dropdownStyling}
          min={0}
          max={100}
          step={0.05}
          precision={2}
          value={autoPaymentFee}
          onChange={value => setAutoPaymentFee(value === "" ? undefined : value)}
          parser={value => value.replace("%", "")}
          formatter={value => (!Number.isNaN(parseFloat(value)) ? `${value}%` : "")}
          stepHoldDelay={500}
          stepHoldInterval={100}
        />
      </div>
    </div>
  );
}

interface ProductTrackerPanelProps {
  feeList: VolumeFeeStep[];
  hasProductTracker: boolean;
  setFeeList: (value: VolumeFeeStep[]) => void;
  setHasProductTracker: (value: boolean) => void;
  supplier: Supplier | undefined;
  discountFeeList: VolumeFeeStep[];
  hasDiscount: boolean;
  hasNoosSplit: boolean;
  setNoosSplit: (value: boolean) => void;
  hasNoosSplitMonths: number;
  setNoosSplitMonths: (value: number) => void;
  setDiscountFeeList: (value: VolumeFeeStep[]) => void;
  setHasDiscount: (value: boolean) => void;
  today: Date;
  discountStartDate: Date;
  discountEndDate: Date;
  setDiscountEndDate: (value: Date) => void;
}

export function ProductTrackerPanel(props: ProductTrackerPanelProps) {
  return (
    <div className="border p-4">
      <Checkbox
        label={"Product Tracker"}
        styles={dropdownStyling}
        checked={props.hasProductTracker}
        onChange={e => props.setHasProductTracker(e.currentTarget.checked)}
      />
      <div className={props.hasProductTracker ? "pt-2" : "hidden"}>
        <FeeScalingList
          feeList={props.feeList}
          setFeeList={props.setFeeList}
          currency={props.supplier?.currency ?? "nil"}
          supplier={props.supplier}
        />
        <div className="border p-4 mt-4">
          <div>
            <Checkbox
              label={"Split NOOS"}
              checked={props.hasNoosSplit}
              onChange={e => props.setNoosSplit(e.currentTarget.checked)}
              className={"py-2"}
              styles={dropdownStyling}
            />
          </div>
          <div className={props.hasNoosSplit ? "" : "hidden"}>
            <p>Months Split</p>
            <NumberInput
              disabled={!props.hasNoosSplit}
              styles={dropdownStyling}
              min={0}
              max={12}
              step={1}
              precision={2}
              value={props.hasNoosSplitMonths}
              onChange={(value: number) => {
                props.setNoosSplitMonths(value);
              }}
              stepHoldDelay={500}
              stepHoldInterval={100}
            />
          </div>
        </div>
        <div className="border p-4 mt-4">
          <div className="">
            <Checkbox
              label={"Initial Discount"}
              styles={dropdownStyling}
              checked={props.hasDiscount}
              onChange={e => props.setHasDiscount(e.currentTarget.checked)}
            />
          </div>
          <div className={props.hasDiscount ? "" : "hidden"}>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <DateInputField
                  disabled
                  label="Discount start date (same as agreement start date)"
                  size="sm"
                  placeholder="Start date"
                  today={props.today}
                  value={props.discountStartDate}
                  required={false}
                />
              </div>
              <div>
                <DateInputField
                  label="Discount end date"
                  size="sm"
                  placeholder="End date"
                  today={props.today}
                  value={props.discountEndDate}
                  onChange={value => props.setDiscountEndDate(value ?? props.today)}
                  required
                />
              </div>
            </div>
            <FeeScalingList
              feeList={props.discountFeeList}
              setFeeList={props.setDiscountFeeList}
              currency={props.supplier?.currency ?? "nil"}
              supplier={props.supplier}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
