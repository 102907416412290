import { useCallback, useRef } from "react";
import { OnClickOutside } from "@impulso/common/common/utilities/OnClickoutside";
import React from "react";

export type CustomDropdownProps = {
  open: boolean;
  setOpen: (value: boolean) => void;
  children: JSX.Element | JSX.Element[] | never;
  body: JSX.Element | JSX.Element[] | never;
  xAlign?: "left" | "right";
};

export default function CustomSelectElement(props: CustomDropdownProps) {
  const wrapperRef = useRef(null);
  OnClickOutside(
    wrapperRef,
    useCallback(() => {
      props.setOpen(false);
    }, [props]),
  );

  const alignment = () => {
    if (!props.xAlign) {
      return "left-0";
    }

    switch (props.xAlign) {
      case "left":
        return "left-0";
      case "right":
        return "right-0";
    }
  };

  return (
    <div className={`relative`} ref={wrapperRef}>
      <div
        className="hover:cursor-pointer"
        onClick={() => {
          props.setOpen(!props.open);
        }}
      >
        {props.children}
      </div>
      <div
        className={`absolute ${props.open ? "" : "scale-y-0 opacity-0"} origin-top bg-white drop-shadow-md w-fit mt-2 z-20 transition-all ${alignment()}`}
      >
        {props.body}
      </div>
    </div>
  );
}
