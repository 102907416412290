export default function Gear() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.79764 3L6.28907 1.73143C6.37195 1.51664 6.51776 1.3319 6.70741 1.20139C6.89706 1.07087 7.12171 1.00068 7.35193 1H8.28907C8.51929 1.00068 8.74394 1.07087 8.93359 1.20139C9.12324 1.3319 9.26905 1.51664 9.35193 1.73143L9.84336 3L11.5119 3.96L12.8605 3.75429C13.0851 3.72381 13.3136 3.76077 13.5171 3.86048C13.7206 3.9602 13.8898 4.11815 14.0034 4.31429L14.4605 5.11429C14.5776 5.31354 14.6316 5.54363 14.6153 5.7742C14.599 6.00476 14.5131 6.22495 14.3691 6.40571L13.5348 7.46857V9.38857L14.3919 10.4514C14.536 10.6322 14.6218 10.8524 14.6381 11.0829C14.6545 11.3135 14.6005 11.5436 14.4834 11.7429L14.0262 12.5429C13.9127 12.739 13.7434 12.8969 13.5399 12.9967C13.3365 13.0964 13.1079 13.1333 12.8834 13.1029L11.5348 12.8971L9.86621 13.8571L9.37479 15.1257C9.29191 15.3405 9.14609 15.5252 8.95644 15.6558C8.76679 15.7863 8.54215 15.8565 8.31193 15.8571H7.35193C7.12171 15.8565 6.89706 15.7863 6.70741 15.6558C6.51776 15.5252 6.37195 15.3405 6.28907 15.1257L5.79764 13.8571L4.12907 12.8971L2.7805 13.1029C2.55595 13.1333 2.3274 13.0964 2.12391 12.9967C1.92041 12.8969 1.75116 12.739 1.63764 12.5429L1.1805 11.7429C1.06336 11.5436 1.00939 11.3135 1.02571 11.0829C1.04203 10.8524 1.12788 10.6322 1.27193 10.4514L2.10621 9.38857V7.46857L1.24907 6.40571C1.10503 6.22495 1.01918 6.00476 1.00285 5.7742C0.986531 5.54363 1.0405 5.31354 1.15764 5.11429L1.61479 4.31429C1.7283 4.11815 1.89756 3.9602 2.10105 3.86048C2.30455 3.76077 2.53309 3.72381 2.75764 3.75429L4.10621 3.96L5.79764 3ZM5.53479 8.42857C5.53479 8.88064 5.66884 9.32256 5.92 9.69845C6.17116 10.0743 6.52814 10.3673 6.9458 10.5403C7.36345 10.7133 7.82304 10.7586 8.26642 10.6704C8.70981 10.5822 9.11708 10.3645 9.43674 10.0448C9.75641 9.72515 9.9741 9.31788 10.0623 8.87449C10.1505 8.43111 10.1052 7.97153 9.93223 7.55387C9.75922 7.13621 9.46626 6.77923 9.09037 6.52807C8.71449 6.27691 8.27257 6.14286 7.8205 6.14286C7.21429 6.14286 6.63291 6.38367 6.20426 6.81233C5.7756 7.24098 5.53479 7.82236 5.53479 8.42857V8.42857Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
