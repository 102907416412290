import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Title } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

import { RtkError, getErrorMessage } from "@impulso/common/common/utilities/ErrorUtils";
import { ErrorAlert } from "./panels/Alert";

type PageProps = {
  titleKey: string;
  hasAccess: boolean;
  leftAction?: React.ReactNode;
  rightAction?: React.ReactNode;
  grid?: boolean;
  children?: React.ReactNode;
  responsive?: boolean;
  isMobile?: boolean;
  contentId?: string;
  error?: RtkError;
};

export const Page: React.FC<PageProps> = props => {
  const { t } = useTranslation();

  if (!props.hasAccess) {
    return <AccessDenied pageTitleKey={props.titleKey} />;
  }

  const sizeLimits = "desktop-plus:min-w-[1350px] desktop-plus:max-w-[1350px] desktop-plus:mx-auto ";

  const id = props.titleKey.replace(" ", "_").toLowerCase();

  return (
    <div
      id={id}
      className={"flex flex-col " + (props.responsive && props.isMobile ? "p-0 pt-6" : "p-6 pt-0 mt-[40px]")}
    >
      <div
        className={
          "flex justify-between items-center v-tablet:items-stretch " +
          (props.isMobile ? "px-6" : "") +
          (props.responsive ? " v-tablet:flex-col flex-row" : " flex-row")
        }
      >
        <div className={"flex items-start"}>
          {props.leftAction}
          <Title id={"title." + id} key={props.titleKey} size={"xx-large"} weight={400}>
            {t(props.titleKey)}
          </Title>
        </div>
        {props.rightAction}
      </div>
      <div
        id={props.contentId ?? ""}
        className={`${props.responsive ? sizeLimits : "w-viewport mx-auto"} ${props.grid ? props.responsive && "desktop-plus:grid-cols-18 h-tablet:grid-cols-6 " + "grid grid-cols-12 mt-4" : ""} ${props.grid && props.isMobile ? "gap-0" : ""} ${props.grid && !props.isMobile ? "gap-6" : ""}`}
      >
        {props.error !== undefined ? (
          <ErrorAlert className="col-start-4 h-tablet:col-start-1 col-span-6 h-tablet:mt-[50px]">
            <p>{getErrorMessage(props.error)}</p>
          </ErrorAlert>
        ) : (
          props.children
        )}
      </div>
    </div>
  );
};

export const AccessDenied = (props: { pageTitleKey: string }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex flex-col text-center content-around justify-center h-[60vh]">
      <FontAwesomeIcon className="text-brand" icon={faTriangleExclamation} size="4x" />
      <p className="font-semibold text-3xl mt-4">{t("ui.moduleNoAccess", { title: props.pageTitleKey })}</p>
    </div>
  );
};
