import { AnyAction, createSlice, PayloadAction, ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "../Store";
import { organisationApi } from "../api/OrganisationApi";

export type UserSlice = {
  token?: string;
};

/**
 * Clears all the user specific user state, is not the same as logging out
 *
 * Since all of this is kept in RAM, it will be cleared when logging out as well, as part of the redirect
 */
export function clearUserState(): ThunkAction<void, RootState, unknown, AnyAction> {
  return async dispatch => {
    await dispatch(clearToken());
    await dispatch(organisationApi.util.resetApiState());
  };
}

const slice = createSlice({
  name: "user",
  initialState: {} as UserSlice,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    clearToken: state => {
      state.token = undefined;
    },
  },
});

export const { setToken, clearToken } = slice.actions;

export default slice.reducer;
