export interface IFilterItem {
  label: string;
  value: string;
  active: boolean;
}

export interface IActiveFilter<Key extends string> {
  key: Key;
  item: IFilterItem;
}

export interface IFilter<Key extends string> {
  values: Record<Key, IFilterItem[]>;
  setActive: (key: Key, itemId: string, active: boolean) => IFilter<Key>;
  addItem: (key: Key, item: IFilterItem) => IFilter<Key>;
  removeItem: (key: Key, item: IFilterItem) => IFilter<Key>;
  clearItems: () => IFilter<Key>;
  overwrite: (key: Key, items: IFilterItem[]) => IFilter<Key>;
  getItems: (key: Key, activeOnly?: boolean) => IFilterItem[];
  getAllActiveItems: () => IActiveFilter<Key>[];
  validate: (key: Key, value: string) => boolean;
  toQueryString: () => string;
}

export const generalFilter = <Key extends string>(initialValues: Record<Key, IFilterItem[]>): IFilter<Key> => {
  const values = initialValues;

  function setActive(key: Key, itemId: string, active: boolean) {
    if (!(key in values)) {
      return generalFilter(values);
    }

    const index = values[key].findIndex(item => item.value === itemId);

    return generalFilter<Key>({
      ...values,
      [key]: values[key].map((val, i) => (index === i ? { ...val, active } : val)),
    });
  }

  function addItem(key: Key, item: IFilterItem) {
    if (!(key in values) || hasItem(key, item.value)) {
      return generalFilter(values);
    }
    return generalFilter<Key>({
      ...values,
      [key]: values[key].concat({ ...item }),
    });
  }

  function removeItem(key: Key, item: IFilterItem) {
    if (!(key in values)) {
      return generalFilter(values);
    }
    return generalFilter<Key>({
      ...values,
      [key]: values[key].filter(i => i.value !== item.value),
    });
  }

  function clearItems() {
    for (const key in values) {
      values[key as Key] = [];
    }

    return generalFilter<Key>(
      Object.keys(values).reduce((obj, key) => {
        obj[key] = [];
        return obj;
      }, {} as any),
    );
  }

  function hasItem(key: Key, value: string): boolean {
    if (!(key in values)) {
      return false;
    }

    return values[key].filter(v => v.value === value).length > 0;
  }

  function getItems(key: Key, activeOnly?: boolean): IFilterItem[] {
    if (!(key in values)) return [];
    return activeOnly ? values[key].filter(i => i.active) : values[key];
  }

  function getAllActiveItems(): IActiveFilter<Key>[] {
    const toReturn: IActiveFilter<Key>[] = [];
    for (const key in values) {
      const items = values[key as Key];
      items.forEach(i => {
        if (i.active) toReturn.push({ key: key, item: i });
      });
    }

    return toReturn;
  }

  function validate(key: Key, value: string): boolean {
    if (!(key in values)) return true;
    const activeItems = values[key].filter(i => i.active);

    if (activeItems.length === 0) return true;

    const result = activeItems.filter(i => i.value === value);

    return result.length > 0;
  }

  function overwrite(key: Key, items: IFilterItem[]) {
    if (!(key in values)) {
      return generalFilter(values);
    }
    return generalFilter<Key>({
      ...values,
      [key]: items,
    });
  }

  function toQueryString() {
    return Object.keys(values)
      .map(key => ({ key, value: values[key as Key] }))
      .filter(item => item.value.length !== 0)
      .map(
        item =>
          `${item.key}=${item.value
            .filter(v => v.active)
            .map(v => encodeURIComponent(v.value))
            .join(",")}`,
      )
      .join("&");
  }

  return {
    values,
    setActive,
    addItem,
    removeItem,
    clearItems,
    getItems,
    getAllActiveItems,
    validate,
    overwrite,
    toQueryString,
  };
};
