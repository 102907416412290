import { useForm, UseFormReturnType } from "@mantine/form";
import ReportFoldout from "../ReportFoldout";
import { Checkbox, MultiSelect, Select, TextInput } from "@mantine/core";
import { dropdownStyling } from "@impulso/common/styling/DropdownStyling";
import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { CreateOrganisationBody, useCreateOrganisationMutation } from "src/api/OrganisationApi";
import { CreateBanner } from "../EndpointBanner";
import SearchOrganisationField from "./SearchOrganisation";
import { OrganisationId } from "src/UserProfile";

type FormData = {
  name: string;
  parentOrgId?: string;
  parentOrgName?: string;
  isRetailer: boolean;
  isStore: boolean;
  isSupplier: boolean;
  isRealEstate: boolean;
  currency: string;
  includeVat: boolean;
  swedishOrgNumber: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  dataSource: string;
  autoPayment: boolean;
  productTracker: boolean;
  brands: string[];
};

export default function CreateOrganisationPanel() {
  const [CreateOrganisationPost, { data, status, isError }] = useCreateOrganisationMutation();

  const form = useForm<FormData>({
    validateInputOnBlur: true,
    initialValues: {
      name: "",
      parentOrgId: "",
      parentOrgName: "",
      contactEmail: "",
      contactName: "",
      contactPhone: "",
      address: "",
      postalCode: "",
      city: "",
      country: "",
      dataSource: "",
      currency: "",
      includeVat: true,
      isRealEstate: false,
      isRetailer: false,
      isStore: false,
      isSupplier: false,
      autoPayment: false,
      productTracker: false,
      swedishOrgNumber: "",
      brands: [],
    },

    // functions will be used to validate values at corresponding key
    validate: {
      autoPayment: (value, values) => {
        return value || values.productTracker ? null : "Must select a module package";
      },
      currency: value => {
        return value.length == 3 && value.toUpperCase() === value ? null : "Please select a valid currency.";
      },
    },
  });

  function formToRequest() {
    const body: CreateOrganisationBody = {
      name: form.values.name,
      parentOrgId: form.values.parentOrgId ? form.values.parentOrgId : undefined,
      contactEmail: form.values.contactEmail,
      contactName: form.values.contactName,
      contactPhone: form.values.contactPhone,
      address: form.values.address,
      postalCode: form.values.postalCode,
      city: form.values.city,
      country: form.values.country,
      dataSource: form.values.dataSource,
      currency: form.values.currency,
      includeVat: form.values.includeVat,
      isSupplier: form.values.isSupplier,
      isRetailer: form.values.isRetailer,
      isStore: form.values.isStore,
      isRealEstate: form.values.isRealEstate,
      swedishOrganisationNumber: form.values.swedishOrgNumber,
      organisationModulesRequest: {
        retailerPayment: (form.values.isRetailer || form.values.isStore) && form.values.autoPayment,
        retailerTracking: (form.values.isRetailer || form.values.isStore) && form.values.productTracker,
        supplierPayment: (form.values.isSupplier || form.values.isRealEstate) && form.values.autoPayment,
        supplierTracking: (form.values.isSupplier || form.values.isRealEstate) && form.values.productTracker,
      },
      brands: form.values.isSupplier ? form.values.brands : [],
    };

    return body;
  }

  return (
    <ReportFoldout title="Create Organisation">
      <div className="p-4">
        <form
          className="gap-4 flex flex-col"
          onSubmit={form.onSubmit(values => CreateOrganisationPost({ body: formToRequest() }))}
        >
          <div className="flex justify-between gap-4">
            <TextInput
              className="grow"
              required
              label="Organisation Name"
              styles={dropdownStyling}
              {...form.getInputProps("name")}
              placeholder="Example Organisation AB"
            />
            <div className="flex grow">
              <div className="w-[40%]"></div>
              <SearchOrganisationField
                label="Parent Organisation"
                currentOrg={form.values.parentOrgName}
                clearSearch={() => {
                  form.setValues({ parentOrgName: "", parentOrgId: "" });
                }}
                fetchOrganisation={(label: string, value: OrganisationId) => {
                  form.setValues({ parentOrgName: label, parentOrgId: value });
                }}
              />
            </div>
          </div>
          <div className="flex items-start gap-4">
            <TextInput
              className="w-[35%]"
              required={form.values.autoPayment}
              label="Swedish Organisation Number"
              placeholder="AABBCC-XXXX / AABBCCXXXX"
              styles={dropdownStyling}
              {...form.getInputProps("swedishOrgNumber")}
            />
            <Select
              label="Currency"
              required
              styles={dropdownStyling}
              {...form.getInputProps("currency")}
              placeholder="SEK"
              data={["SEK", "NOK", "DKK", "EUR"]}
            />
            <Checkbox
              label="Include VAT"
              className="mt-8"
              styles={dropdownStyling}
              {...form.getInputProps("includeVat", { type: "checkbox" })}
            />
          </div>
          <div className="flex gap-4">
            <div>
              <p className="text-sm">Organisation Type</p>
              <div className="flex justify-between gap-8 border border-gray-400 p-2 w-fit">
                <Checkbox
                  label="Supplier"
                  styles={dropdownStyling}
                  {...form.getInputProps("isSupplier", { type: "checkbox" })}
                />
                <Checkbox
                  label="Retailer"
                  styles={dropdownStyling}
                  {...form.getInputProps("isRetailer", { type: "checkbox" })}
                />
                <Checkbox
                  label="Store"
                  styles={dropdownStyling}
                  {...form.getInputProps("isStore", { type: "checkbox" })}
                />
                <Checkbox
                  label="Real Estate"
                  styles={dropdownStyling}
                  {...form.getInputProps("isRealEstate", { type: "checkbox" })}
                />
              </div>
            </div>
            <div>
              <p className="text-sm">Modules</p>
              <div className="flex justify-between gap-8 border border-gray-400 p-2 w-fit h-[38px]">
                <Checkbox
                  label="Auto-Payment"
                  styles={{
                    error: { marginTop: 12, marginLeft: -32, width: 50, whiteSpace: "nowrap" },
                    ...dropdownStyling,
                  }}
                  {...form.getInputProps("autoPayment", { type: "checkbox" })}
                />
                <Checkbox
                  label="Product Tracker"
                  styles={dropdownStyling}
                  {...form.getInputProps("productTracker", { type: "checkbox" })}
                />
              </div>
            </div>
          </div>
          {form.values.isSupplier && (
            <div>
              <BrandInput form={form} />
            </div>
          )}
          <div className="flex gap-4">
            <TextInput
              label="Contact Email"
              styles={dropdownStyling}
              {...form.getInputProps("contactEmail")}
              onChange={event => {
                form.setFieldValue("contactEmail", event.currentTarget.value.replaceAll(" ", ""));
              }}
              placeholder="example@mail.com"
            />
            <TextInput
              label="Contact Name"
              styles={dropdownStyling}
              {...form.getInputProps("contactName")}
              placeholder="Example Nameson"
            />
            <TextInput
              label="Contact Phone"
              styles={dropdownStyling}
              {...form.getInputProps("contactPhone")}
              placeholder="+46"
            />
          </div>
          <div className="flex gap-4">
            <TextInput
              label="Address"
              styles={dropdownStyling}
              {...form.getInputProps("address")}
              placeholder="Address 10"
            />
            <TextInput
              label="PostalCode"
              styles={dropdownStyling}
              {...form.getInputProps("postalCode")}
              placeholder="411 10"
            />
            <TextInput label="City" styles={dropdownStyling} {...form.getInputProps("city")} placeholder="City Name" />
            <TextInput
              label="Country"
              styles={dropdownStyling}
              {...form.getInputProps("country")}
              placeholder="Country Name"
            />
            <PrimaryButton
              label="Create"
              extraStyle="w-min"
              margin="ml-auto mt-auto"
              padding="py-2 px-8"
              type="submit"
            />
          </div>
        </form>
      </div>
      <CreateBanner data={data} status={status} isError={isError} />
    </ReportFoldout>
  );
}

export function BrandInput<T extends { brands: string[] }>({ form }: { form: UseFormReturnType<T> }) {
  return (
    <MultiSelect
      size="sm"
      data={form.values.brands}
      value={form.values.brands}
      placeholder={"Brands..."}
      label="Brands"
      styles={dropdownStyling}
      searchable
      creatable
      nothingFound="Enter new brand by typing"
      getCreateLabel={query => `+ Create ${query}`}
      onChange={value => form.setFieldValue("brands", value as any)}
      onCreate={value => {
        form.setFieldValue("brands", [...form.values.brands, value] as any);
        return value;
      }}
    />
  );
}
