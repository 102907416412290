export default function Sortable() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 6L7.72632 1.12063C7.76169 1.0825 7.80417 1.05215 7.85121 1.03142C7.89824 1.01069 7.94886 1 8 1C8.05114 1 8.10176 1.01069 8.14879 1.03142C8.19583 1.05215 8.23831 1.0825 8.27368 1.12063L13 6"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 10L7.72632 14.8794C7.76169 14.9175 7.80417 14.9479 7.85121 14.9686C7.89824 14.9893 7.94886 15 8 15C8.05114 15 8.10176 14.9893 8.14879 14.9686C8.19583 14.9479 8.23831 14.9175 8.27368 14.8794L13 10"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
