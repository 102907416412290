export default function CalenderIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_393_14478)">
        <path d="M1.33337 2.66669H14.6667V14.6667H1.33337V2.66669Z" stroke="currentcolor" strokeWidth="1.5" />
        <path d="M4.66663 0.666687V2.66669" stroke="currentcolor" strokeWidth="1.5" />
        <path d="M11.3333 0.666687V2.66669" stroke="currentcolor" strokeWidth="1.5" />
        <path d="M1.33337 5.33331H14.6667" stroke="currentcolor" strokeWidth="1.5" />
        <path d="M3.33337 8.33331H5.33337" stroke="currentcolor" strokeWidth="1.5" />
        <path d="M3.33337 11.6667H5.33337" stroke="currentcolor" strokeWidth="1.5" />
        <path d="M7 8.33331H9" stroke="currentcolor" strokeWidth="1.5" />
        <path d="M10.6666 8.33331H12.6666" stroke="currentcolor" strokeWidth="1.5" />
        <path d="M7 11.6667H9" stroke="currentcolor" strokeWidth="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_393_14478">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
