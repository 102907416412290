import { Loader } from "@mantine/core";
import Download from "@impulso/common/Icons/Download";
import { ButtonProps } from "./PrimaryButton";

export function ExportButton(props: ButtonProps) {
  const style = "flex gap-2 items-center justify-center select-none relative bg-white hover:text-brand";
  const activeStyle = "cursor-pointer active:translate-y-[1px] active:text-brand-900 rounded-full";
  const disabledStyle = "text-gray-900";
  const disabled = props.disabled || props.loading;

  return (
    <>
      <button
        disabled={disabled}
        type={props.type}
        onClick={props.onClick}
        className={`${style} ${disabled ? disabledStyle : activeStyle} ${props.textSize ?? "text-xs"} ${props.padding ?? "p-[2px] px-[2px]"} ${props.margin}`}
      >
        <Loader
          size="sm"
          className={`${props.loading ? "visible" : "invisible"} absolute left-[50%] translate-x-[-50%]`}
        />
        <div className={`${props.loading ? "opacity-0" : "opacity-100"} flex items-center gap-2`}>
          <p className="underline">{props.label}</p>
          {props.rightIcon ?? <Download />}
        </div>
      </button>
    </>
  );
}
