const retailerPaymentModules: string[] = [
  "impulso.store.sales.view",
  "impulso.store.sales",
  "impulso.store.withdrawals.view",
  "impulso.store.withdrawals",
  "impulso.retailer.fortnox.initiate",
  "impulso.retailer.sitoo.initiate",
  "impulso.retailer.front-systems.initiate",
  "impulso.retailer.shopify.initiate",
  "impulso.store.dashboard",
  "impulso.inventory.articles.view",
  "impulso.payment",
  "impulso.users",
];

const retailerTrackingModules: string[] = [
  "impulso.retailer.articles.view",
  "impulso.store.stock.view",
  "impulso.retailer.stock.view",
  "impulso.inventory.view",
  "impulso.retailer.inventory.view",
  "impulso.store.dashboard",
  "impulso.inventory.articles.view",
  "impulso.product-tracker.view",
  "impulso.users",
];

const supplierPaymentModules: string[] = [
  "supplier.payment",
  "impulso.supplier.sales.view",
  "impulso.supplier.dashboard",
  "impulso.supplier.payments",
  "impulso.supplier.sales",
  "impulso.inventory.articles.upload",
  "impulso.inventory.articles.view",
  "impulso.payment",
  "impulso.users",
];

const supplierTrackingModules: string[] = [
  "supplier.tracking",
  "impulso.inventory.view",
  "impulso.supplier.inventory.view",
  "impulso.inventory.articles.upload",
  "impulso.inventory.articles.view",
  "impulso.supplier.dashboard",
  "impulso.supplier.stock.view",
  "impulso.product-tracker.view",
  "impulso.users",
];

export function getModuleList(isSupplier: boolean, isRetailer: boolean): string[] {
  let modules: string[] = ["impulso.agreements.view", "impulso.agreements.create", "impulso.notifications"];
  modules = modules.concat(isSupplier ? supplierPaymentModules : []);
  modules = modules.concat(isSupplier ? supplierTrackingModules : []);
  modules = modules.concat(isRetailer ? retailerPaymentModules : []);
  modules = modules.concat(isRetailer ? retailerTrackingModules : []);

  return Array.from(new Set(modules));
}
