import { Badge as MantineBadge } from "@mantine/core";
import { colors } from "@impulso/common/Theme";
import React from "react";

export type BadgeProps = {
  className?: string;
  color?: string;
  children: React.ReactNode;
};

export default function Badge(props: BadgeProps) {
  return (
    <MantineBadge
      variant="filled"
      size="xs"
      className={props.className}
      styles={{
        root: { borderRadius: 0, backgroundColor: colors.brand["200"] },
        inner: { color: "#000", fontWeight: "normal" },
      }}
    >
      {props.children}
    </MantineBadge>
  );
}

export function SimpleBadge(props: BadgeProps) {
  return (
    <div
      className={`p-2 text-S ${props.color ?? "bg-brand-200"} whitespace-nowrap flex items-center ${props.className}`}
    >
      {props.children}
    </div>
  );
}
