import { VolumeFeeStep } from "src/api/AgreementApi";

interface ProductTrackerProps {
  volumeSteps: VolumeFeeStep[];
  startDate?: string;
  currency: string;
  volumeLabel: string;
  feeLabel: string;
}

export function ProductTrackerFeePlan({
  volumeSteps,
  startDate,
  currency,
  volumeLabel,
  feeLabel,
}: ProductTrackerProps) {
  return (
    <div>
      <div className="flex flex-row gap-1 justify-between">
        <p className="text-sc text-gray-600 uppercase">{volumeLabel}</p>
        <p className="text-sc text-gray-600 uppercase">{feeLabel}</p>
      </div>
      <div className="flex flex-col mt-1 border">
        {startDate && (
          <div key="ValidDates" className="grid grid-cols-3 py-2 px-4 bg-gray-300">
            <p className="text-M text-gray-900 font-semibold">Valid from:</p>
            <p className="text-M text-gray-900 font-semibold text-right col-span-2">{startDate}</p>
          </div>
        )}
        {volumeSteps.length === 1 ? (
          <div key={volumeSteps[0].fromVolume} className="grid grid-cols-2 py-2 px-4 bg-gray-100">
            <p className="text-M text-gray-900 font-semibold">Flat rate</p>
            <p className="text-M text-gray-900 font-semibold text-right">{volumeSteps[0].wholesaleFee.toFixed(2)}%</p>
          </div>
        ) : (
          volumeSteps.map((step, i) => {
            const toVolume = volumeSteps[i + 1]?.fromVolume;
            const bgCol = i % 2 === 0 ? "bg-gray-100" : "bg-gray-300";
            return (
              <div key={step.fromVolume} className={"grid grid-cols-2 py-2 px-4 " + bgCol}>
                {toVolume ? (
                  <p className="text-M text-gray-900 font-semibold">{formatStep(step, currency, toVolume)}</p>
                ) : (
                  <p className="text-M text-gray-900 font-semibold">{`${step.fromVolume.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")}+ ${currency}`}</p>
                )}
                <p className="text-M text-gray-900 font-semibold text-right">{step.wholesaleFee.toFixed(2)}%</p>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
}

function formatStep(step: VolumeFeeStep, currency: string, toVolume?: number) {
  return `${step.fromVolume.toLocaleString()} ${currency} - ${toVolume === undefined ? "" : toVolume.toLocaleString() + " " + currency}`;
}
