export const dropdownStyling = {
  input: { borderRadius: 0, "&:hover": { borderColor: "#000" } },
  dropdown: { borderRadius: 0, padding: 0 },
  item: {
    borderRadius: 0,
    fontSize: "12px",
    "&[data-active]": {
      backgroundColor: "#000",
    },
  },
  value: { borderRadius: 0, fontSize: "12px" },
  values: { input: { fontSize: "12px" } },
};
