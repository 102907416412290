export default function Home() {
  return (
    <svg
      className="block item-center"
      width="16"
      height="16"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22 22H2V11L12 2L22 11V22Z" stroke="currentcolor" strokeWidth="2" />
    </svg>
  );
}
