import Download from "@impulso/common/Icons/Download";
import { Language } from "@impulso/common/Language";
import { Page } from "@impulso/common/components/Page";
import { PrimaryButton } from "@impulso/common/components/buttons/PrimaryButton";
import { CalenderButton } from "@impulso/common/components/calender/CalenderButton";
import { AsyncLinkDownload } from "@impulso/common/components/link";
import { TableReportColumn } from "@impulso/common/components/report/DataTable";
import { TableReport } from "@impulso/common/components/report/TableReport";
import SearchBar, { SearchResult } from "@impulso/common/components/searchBar/SearchBar";
import { IFilter, IFilterItem, generalFilter } from "@impulso/common/filters/generalFilter";
import { Skeleton } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { format } from "date-fns";
import { addDays } from "date-fns/addDays";
import { subDays } from "date-fns/subDays";
import { useMemo, useState } from "react";
import { OrganisationId } from "src/UserProfile";
import { downloadTrackedWholesale, downloadTrackedWholesaleForSupplier } from "src/api/DownloadApi";
import { useGetTrackedWholesaleQuery, useGetTrackedWholesaleSearchResultQuery } from "src/api/ProductTrackerApi";
import { SupplierWholesale } from "src/api/models/ProductTracker";
import { useGlobalSecurity } from "src/common/UseGlobalSecurity";

export type TrackedWholesaleFilterKey = "brands" | "retailers" | "suppliers";
export type TrackedWholesaleFilter = IFilter<TrackedWholesaleFilterKey>;
export type TrackedWholesaleSearchResult = SearchResult<TrackedWholesaleFilterKey>;

const defaultFilterData: Record<TrackedWholesaleFilterKey, IFilterItem[]> = {
  brands: [],
  retailers: [],
  suppliers: [],
};

export function TrackedWholesaleReport() {
  const last30Days: [Date, Date] = [subDays(new Date(), 30), new Date()];
  const [dateSpan, setDateSpan] = useState<[Date, Date]>(last30Days);

  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const { accessToken } = useGlobalSecurity();

  const startDate = format(dateSpan[0], "yyyy-MM-dd");
  const endDate = format(addDays(dateSpan[1], 1), "yyyy-MM-dd");

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState(generalFilter(defaultFilterData));

  const [debouncedSearch] = useDebouncedValue(search, 500);
  const minSearchLength = 3;
  const { data: searchResults, isFetching } = useGetTrackedWholesaleSearchResultQuery(
    { searchQuery: debouncedSearch },
    { skip: debouncedSearch.length < minSearchLength },
  );

  const { data, isFetching: isLoading } = useGetTrackedWholesaleQuery({
    startDate,
    endDate,
    filterQuery: filter.toQueryString(),
  });

  async function onExportXls() {
    try {
      setIsLoadingExport(true);
      await downloadTrackedWholesale(startDate, endDate, filter.toQueryString(), accessToken!);
    } finally {
      setIsLoadingExport(false);
    }
  }

  const columnData = useMemo(
    () => createColumns({ startDate: startDate, endDate: endDate, accessToken: accessToken!, filter }),
    [accessToken, endDate, startDate, filter],
  );

  return (
    <Page
      hasAccess={true}
      titleKey="trackedWholesaleReport.title"
      rightAction={
        <div className="mb-4">
          <div className="mb-4">
            <PayoutHeader dateSpan={dateSpan} setDateSpan={setDateSpan} />
          </div>
          <PrimaryButton label={"Download Breakdown"} disabled={isLoadingExport} onClick={onExportXls} />
        </div>
      }
    >
      <div>
        <SearchBar
          filter={filter}
          setFilter={setFilter}
          searchString={search}
          setSearch={setSearch}
          searchResult={searchResults ?? defaultFilterData}
          loading={isFetching}
          minSearchLength={minSearchLength}
          placeholder="Search for supplier, retailer or brand"
          id="admin-tracked-wholesale-searchbar"
        />
        <Overview
          isLoading={isLoading}
          trackedQuantity={data?.trackedQuantity ?? 0}
          totalWholesale={data?.totalWholesale ?? 0}
          payingCustomers={data?.payingCustomers ?? 0}
          allCustomers={data?.allCustomers ?? 0}
        />
        <CurrencyRates
          sek={data?.sekConversionRate!}
          dkk={data?.dkkConversionRate!}
          nok={data?.nokConversionRate!}
          gbp={data?.gbpConversionRate!}
        />
        <TableReport
          pageSize={50}
          rows={data?.suppliers ?? []}
          columns={columnData}
          defaultSort={{
            accessor: "supplierOrgId",
            direction: "asc",
          }}
          isLoading={isLoading}
          loadingText={"Loading"}
          idAccessor={a => a.supplierOrgId}
        />
      </div>
    </Page>
  );
}

function PayoutHeader(props: { setDateSpan: (value: [Date, Date]) => void; dateSpan: [Date, Date] }) {
  return (
    <div className="">
      <div>
        <CalenderButton
          dateSpan={props.dateSpan}
          setDateSpan={props.setDateSpan}
          boxSize="v-tablet:w-full w-[240px] h-[32px]"
        />
      </div>
    </div>
  );
}

interface CreateColumnsProps {
  startDate: string;
  endDate: string;
  accessToken: string;
  filter: TrackedWholesaleFilter;
}

function createColumns(props: CreateColumnsProps): TableReportColumn<SupplierWholesale>[] {
  return [
    { accessor: "supplierName", sortable: true, title: "Supplier", visibility: "alwaysVisible" },
    {
      accessor: "totalTrackedQuantity",
      sortable: true,
      textAlignment: "right",
      title: "Quantity",
      visibility: "visible",
    },
    {
      accessor: "totalWholesale",
      sortable: true,
      textAlignment: "right",
      title: "Wholesale (ex VAT)",
      visibility: "visible",
      render: renderWholesale("totalWholesale", "EUR"),
    },
    {
      accessor: "isPaying",
      sortable: true,
      textAlignment: "left",
      title: "Customer Type",
      visibility: "visible",
      render: tw => renderIsPaying({ isPaying: tw.isPaying }),
    },
    {
      accessor: "retailerCount",
      sortable: true,
      textAlignment: "left",
      title: "Retailers",
      visibility: "visible",
      render: tw =>
        retailerDownload({
          supplierOrgId: tw.supplierOrgId,
          supplierName: tw.supplierName,
          retailerCount: tw.retailerCount,
          startDate: props.startDate,
          endDate: props.endDate,
          accessToken: props.accessToken,
          filter: props.filter,
        }),
    },
  ];
}

function renderIsPaying(props: { isPaying: boolean }) {
  return props.isPaying ? "Paying" : "Free Trial";
}

interface RetailerDownloadProps {
  supplierOrgId: OrganisationId;
  supplierName: string;
  retailerCount: number;
  startDate: string;
  endDate: string;
  accessToken: string;
  filter: TrackedWholesaleFilter;
}

function retailerDownload(props: RetailerDownloadProps) {
  const doDownloadTrackedWholesaleForSupplier = (supplierOrgId: OrganisationId, supplierName: string) =>
    downloadTrackedWholesaleForSupplier(
      supplierOrgId,
      supplierName,
      props.startDate,
      props.endDate,
      props.filter.toQueryString(),
      props.accessToken,
    );

  return (
    <AsyncLinkDownload
      icon={<Download />}
      onClick={() => doDownloadTrackedWholesaleForSupplier(props.supplierOrgId, props.supplierName)}
      label={props.retailerCount + " retailer(s)"}
    />
  );
}

function renderWholesale(key: keyof SupplierWholesale, currency: string) {
  return (item: SupplierWholesale) => {
    if (item[key] === undefined) {
      return "";
    }

    return Language.FormatMoney(item[key] as number, currency);
  };
}

interface OverviewProps {
  isLoading: boolean;
  trackedQuantity: number;
  totalWholesale: number;
  payingCustomers: number;
  allCustomers: number;
}

export function Overview(props: OverviewProps) {
  return (
    <div className="grid desktop:grid-cols-4 mt-6 grid-cols-2 v-tablet:grid-cols-1 gap-[24px]">
      <OverviewItem
        title="Tracked Quantity"
        amount={props.trackedQuantity.toString()}
        unit={"PCS"}
        loading={props.isLoading}
      />
      <OverviewItem
        title="Wholesale (ex VAT)"
        amount={Language.FormatMoney(props.totalWholesale)}
        unit="EUR"
        loading={props.isLoading}
      />
      <OverviewItem
        title="Paying Customers"
        amount={props.payingCustomers.toString()}
        unit=""
        loading={props.isLoading}
      />
      <OverviewItem title="Customers" amount={props.allCustomers.toString()} unit="" loading={props.isLoading} />
    </div>
  );
}

function OverviewItem(props: { title: string; amount: string; unit: string; loading?: boolean }) {
  return (
    <div className="flex flex-col border-t border-gray-300 pt-2 gap-[7px] col-span-1 mb-6">
      <p className="font-sans font-normal text-sc text-gray-600">{props.title.toUpperCase()}</p>
      <Skeleton visible={props.loading} radius={0}>
        <p className="font-serif text-4xl">
          {props.amount}
          <span className="ml-2 font-sans text-l">{props.unit}</span>
        </p>
      </Skeleton>
    </div>
  );
}

interface CurrencyRatesProps {
  sek: number;
  dkk: number;
  nok: number;
  gbp: number;
}

function CurrencyRates(props: CurrencyRatesProps) {
  const style = "font-sans font-normal text-sc text-gray-500";

  return (
    <div className="mb-6 flex grid grid-cols-4 gap-[24px]">
      <p className={style}>{"1 EUR = " + Language.FormatMoney(props.sek) + " SEK"}</p>
      <p className={style}>{"1 EUR = " + Language.FormatMoney(props.nok) + " NOK"}</p>
      <p className={style}>{"1 EUR = " + Language.FormatMoney(props.dkk) + " DKK"}</p>
      <p className={style}>{"1 EUR = " + Language.FormatMoney(props.gbp) + " GBP"}</p>
    </div>
  );
}
